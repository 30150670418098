export default {
    "pages": {
        "forTranslators": "Для Перекладачів",
        "account": "Обліковий запис",
        "contact": "Зв’язок",
        "regulations": "Регламент",
        "enquiries": "Питання",
        "search": "Пошук",
        "privacyPolicy": "Політика конфіденційності"
    },
    "header": {
        "closeAlt": "Закрити",
        "logout": "Вийти",
        "accountIconAlt": "Іконка облікового запису",
        "searchIconAlt": "Іконка лупи",
        "searchTranslator": "Пошук перекладача",
        "selectPageLang": "Вибрати мову сайту"
    },
    "footer": {
        "accountIconAlt": " Іконка облікового запису",
        "searchIconAlt": " Іконка лупи",
        "messageIconAlt": "Іконка повідомлень",
        "enquiriesIconAlt": "Іконка перекладів"
    },
    "searchSection": {
        "langLabel": "Мова",
        "translationTypeLabel": "Вид перекладу",
        "locationLabel": "Населений пункт",
        "defaultTranslationType": "Будь-який",
        "search": "Пошук",
        "resultsCounter": "результатів",
        "outOf": "з",
        "list": "Список",
        "map": "Карта",
        "showFilters": "Показати фільтри",
        "hideFilters": "Приховати фільтри",
        "interpreting": "Тільки усний переклад"
    },
    "wcag": {
        "goToMain": "Перейти на головну",
        "resizeFont": "Змінити розмір тексту",
        "googleMap": "Карта, на якій позначено місця, де знаходяться перекладачі",
        "interpreting": "Вибрати тільки усний переклад"
    },
    "homeView": {
        "findSwornTranslator": "Знайти присяжного перекладача",
        "imageOfSwornTranslatorAlt": "Світлина присяжного перекладача",
        "howItWorks": "Як це працює",
        "itIsEasyText": "Все дуже просто, незалежно від того, чи ви – приватна особа, чи дієте від імені компанії або державного органу (установа, суд, поліція). Ви можете тут знайти всіх присяжних перекладачів з бази Міністерства юстиції.",
        "arrowIconAlt": "Іконка стрілки",
        "searchEngineIconAlt": "Іконка пошуку",
        "searchEngineInfo": "Шукати присяжного перекладача, який надає послуги, що вас цікавлять",
        "contactTheTranslatorIconAlt": "Іконка телефонування",
        "contactTheTranslatorInfo": "Зв’яжіться з вибраним перекладачем та погодьте умови співпраці",
        "sendingDocumentsIconAlt": "Іконка документів",
        "sendingDocumentsInfo": "Перекладач може попросити вас надіслати документи і передасть вам умови виконання праці",
        "signedTranslationsIconAlt": "Іконка підписаних документів",
        "signedTranslationsInfo": "Отримаєте переклад посвідчений підписом перекладача",
        "frequentlyChosenTranslators": "Найпопулярніші перекладачі",
        "showMore": "Показати більше",
        "whoIsSwornTranslator": "Хто такий присяжний перекладач",
        "personOfPublicTrust": "Це офіційна особа – спеціаліст з перекладів, м.ін. процесуальних документів, офіційних документів, має також право завіряти іншомовні копії таких документів і посвідчувати переклади та їх копії, підготовлені іншими особами.",
        "mustCertifyTheComplianceOfTheDocument": "Присяжний перекладач мусить посвідчити печаткою вірність перекладеного документа з оригіналом, така печатка містить його ім’я і прізвище, мову, з якої і на яку має право перекладати, а також його власний номер присяжного перекладача.",
        "certifiedTranslationsInclude": "Найчастіше перекладаються такі документи:",
        "proceduralAndOfficialDocuments": "процесуальні та інші офіційні документи",
        "workCertificates": "трудові книжки",
        "schoolCertificates": "шкільні табелі",
        "notarialDeeds": "нотаріальні акти",
        "civilStatusRecords": "акти цивільного стану",
        "lawsAndRegulations": "закони і накази",
        "certificates": "довідки та сертифікати",
        "imageOfMobileAppAlt": "Світлина телефону із встановленим застосунком",
        "downloadMobileApp": "Завантажити мобільний застосунок",
        "mobileAppDescription": "Nascetur amet magna mattis aliquam ac sit. Sit lobortis nibh quam ultrices dictum commodo scelerisque cras. Ultricies tristique adipiscing sagittis est tincidunt pellentesque. Consectetur accumsan, lobortis ullamcorper ac condimentum sodales velit. Parturient at fermentum, orci quisque mauris scelerisque gravida nulla auctor.",
        "appStoreAlt": "App store",
        "googlePlayAlt": "Google play",
        "logo_unia": "Лоґотип, що представляє прапор Євросоюзу",
        "unia_section": "Лоґотип Євросоюзу, Польщі, та проєкту"
    },
    "forTranslators": {
        "forSwornTranslators": "Для присяжних перекладачів",
        "signUpIfYouAreTranslator": "Ви – перекладач? Зареєструйтеся і активуйте рівень VIP, щоб отримати доступ до багатьох корисних функцій",
        "swornAppIsDedicatedForTranslators": "SwornApp призначений спеціально для присяжних перекладачів!",
        "benefits": "Переваги",
        "free": "Безкоштовний",
        "standard": "Стандарт",
        "vip": "VIP",
        "convenientCommunication": "Зручний зв’язок і обмін документами з клієнтами",
        "abilityToSpecifyPreferredServices": "Можливість вказати бажані послуги",
        "editingBusinessCard": "Можливість повністю відредагувати свою візитну картку",
        "keepingOfTheRepertory": "Можливість ведення реєстру",
        "convenientInvoicing": "Зручний спосіб оформлення рахунків",
        "onlinePaymentsForCustomers": "Онлайн-платежі для клієнтів",
        "checkMarkIconAlt": "Іконка позначення",
        "notAvailableIconAlt": "Іконка недоступності",
        "month": "місяць",
        "freeVipAccount": "Тепер рівень VIP для кожного за 0 злотих протягом першого року, якщо встановити мобільний застосунок SwornApp.",
        "crateVipAccount": "Зареєструйте рівень VIP за 0 злотих",
        "imageOfSwornTranslatorAlt": "Світлина присяжного перекладача",
        "freeAvailable": "доступно з рівнем free",
        "freeNotAvailable": "недоступно з рівнем free",
        "standardAvailable": "доступно з рівнем стандарт",
        "standardNotAvailable": "недоступно з рівнем стандарт",
        "vipAvailable": "доступно з рівнем vip",
        "vipNotAvailable": "недоступно з рівнем vip",
        "priceFree": "рівень free лише",
        "priceStandard": "рівень стандарт лише",
        "priceVip": "рівень vip лише",
        "perMonth": "в місяць"
    },
    "findTranslator": {
        "starIconAlt": "Іконка зірочки",
        "findTranslator": "Знайти присяжного перекладача",
        "loading": "Вантажиться...",
        "showMore": "Показати більше"
    },
    "starsCounter": {
        "starIconAlt": "Іконка зірочки",
        "rating": "Оцінка",
        "ratingOutOf": "на"
    },
    "translatorPage": {
        "pointerIconAlt": "Іконка позначення",
        "mailIconAlt": "Іконка конверту",
        "phoneIconAlt": "Іконка телефону",
        "clockIconAlt": "Іконка годинника",
        "translatorImageAlt": "Світлина перекладача з його облікового запису",
        "translationLanguages": "Мови перекладу",
        "askForOffer": "Запитайте про умови",
        "aboutMe": "Про мене",
        "aboutTranslator": "Про перекладача",
        "aboutTranslatorContent": "Обліковий запис перекладача завантажений зі списку присяжних перекладачів Міністерства юстиції.",
        "signUpAsTranslatorInfo": "Це ваша візитка? Зареєструйтеся і заповніть ваші дані!",
        "signUpAsTranslatorButton": "Створити обліковий запис перекладача",
        "experience": "Досвід",
        "currently": "зараз",
        "education": "Освіта",
        "deadline": "Термін",
        "dateFrom": "від",
        "writtenTranslations": "Письмовий переклад",
        "interpreting": "Усний переклад",
        "yes": "Так",
        "no": "Ні",
        "showMore": "Показати більше",
        "showLess": "показати менше",
        "list": "Список",
        "map": "Карта",
        "sendRequest": "Надіслати запит",
        "sendRequestTo": "до",
        "form": {
            "language": "Мови",
            "formOfTranslation": "Forma",
            "kindOf": "Вид",
            "deliveryMethod": "Спосіб передачі оригіналу",
            "paymentMethod": "Спосіб платежу",
            "deadline": "Термін (до дня)",
            "receivingMethod": "Спосіб отримання перекладу",
            "documentFile": "Документ або зразок",
            "address": "Адреса",
            "fromTime": "Від год.",
            "toTime": "до год.",
            "date": "Дата",
            "addFile": "Додати файл",
            "chooseFile": "Вибрати:",
            "cancel": "Скасувати",
            "send": "Надіслати",
            "contentMessage": "Зміст повідомлення",
            "addFilePlaceholder": "Додати файл",
            "enterMessagePlaceholder": "Ввести повідомлення",
            "sendInquiryTo": "Надіслати запит до:",
            "yourMessage": "Ваше повідомлення",
            "successfullySendInquiry": "Запит надіслано успішно",
            "errorSendInquiry": "Помилка надсилання запиту"
        }
    },
    "translatorCard": {
        "pointerIconAlt": "Іконка дороговказу",
        "address": "Адреса",
        "showOnMap": "Показати на карті",
        "seeProfile": "Переглянути обліковий запис",
        "deadline": "Термін",
        "clockIconAlt": "Іконка годинника",
        "mailIconAlt": "Іконка конверту",
        "mail": "Адреса електронної пошти",
        "phone": "Телефон",
        "phoneIconAlt": "Іконка телефону",
        "arrowRightIconAlt": "Іконка стрілки праворуч",
        "votesNumber": "Кількість голосів",
        "dateFrom": "від",
        "deadlineAriaLabel": "Термін приготування перекладу близько:"
    },
    "orders": {
        "yes": "Так",
        "no": "Ні",
        "search": "Пошук",
        "inProgress": "Замовлення в процесі",
        "toBePaid": "Замовлення, що мають бути оплачені",
        "ended": "Завершені замовлення",
        "principal": "Замовник",
        "admissionDate": "Дата прийняття",
        "completionDate": "Термін виконання",
        "translationForm": "Вид перекладу",
        "valuation": "Оцінка",
        "admitted": "Прийнято",
        "finish": "Завершені",
        "reject": "Відхилити",
        "editOrder": "Редагувати замовлення",
        "status": "Статус",
        "repertory": "Реєстр",
        "markAsPaid": "Позначити як сплачене",
        "payOnline": "Сплатити онлайн",
        "endOrder": "Завершити замовлення",
        "downloadAnInvoice": "Завантажити інвойс",
        "statuses": {
            "None": "відсутній",
            "InProgress": "В процесі",
            "Rejected": "Відхилені",
            "ToBePaid": "Підлягає оплаті",
            "Completed": "Виконані"
        },
        "orderFrom": "Замовлення від",
        "order": "Замовлення",
        "translationPrice": "Вартість перекладу",
        "additionalServices": "Додаткові послуги",
        "gross": "з ПДВ",
        "net": "без ПДВ",
        "pagesNumber": "Кількість сторінок",
        "totalGrossAmount": "Всього з ПДВ",
        "totalPriceToPay": "Всього до сплати",
        "endTime": "Дата завершення",
        "paymentDeadline": "Дата платежу",
        "details": {
            "language": "Мова",
            "formOfTranslation": "Форма",
            "typeOfTranslation": "Вид",
            "deadline": "Термін до",
            "originalDocumentDelivery": "Спосіб надання оригіналу",
            "receiptOfTranslation": "Спосіб отримання перекладу",
            "paymentMethod": "Спосіб платежу",
            "methodOfCalculating": "Спосіб розрахунку вартості послуги",
            "currency": "Валюта"
        },
        "markAsPaidModal": {
            "title": "Підтвердження сплати замовлення",
            "description": "Ви впевнені, що хочете підтвердити оплату замовлення?",
            "markedAsPaid": "Замовлення позначене як оплачене"
        },
        "rejectOrderModal": {
            "titleVIP": "Додати відхилені замовлення у реєстр?",
            "titleBasic": "Підтвердити відхилення замовлення",
            "descriptionBasic": "Вт впевнені, що хочете відхилити замовлення?",
            "orderSuccessfullyRejected": "Замовлення відхилено",
            "cancelButton": "Скасувати",
            "rejectButton": "Відхилити замовлення",
            "createRepertory": "Так",
            "dontCreateRepertory": "Ні",
            "createRepertoryAlt": "Додати у реєстр",
            "dontCreateRepertoryAlt": "Не додавати у реєстр",
            "selectIconAlt": "Іконка вибору"
        },
        "endOrderModal": {
            "saleDate": "Дата продажу",
            "issueDate": "Дата оформлення",
            "paymentDate": "Термін оплати",
            "endOrderButton": "Завершити замовлення",
            "endingOrderButton": "Завершити замовлення...",
            "issueInvoice": "Створити інвойс",
            "provideDataForInvoice": "Вказати замовника для інвойсу",
            "customerName": "Ім’я і прізвище",
            "nip": "Податковий номер NIP",
            "street": "Вулиця",
            "buildingNumber": "Номер будинку",
            "flatNumber": "Номер приміщення",
            "city": "Населений пункт",
            "postcode": "індекс",
            "serviceName": "Назва послуги",
            "pagesNumber": "Кількість сторінок",
            "rate": "Ставка",
            "gross": "з ПДВ",
            "totalAmount": "Всього",
            "plnGrossPrice": "Всього злотих з ПДВ",
            "addAdditionalService": "Додати додаткову послугу",
            "customerAskedForAnInvoice": "Клієнт попросив оформити інвойс",
            "totalPriceToPay": "Всього до сплати",
            "addToRepertory": "Додати у реєстр",
            "priceWithVat": "Ціни з ПДВ",
            "vatRate": "Ставка ПДВ"
        }
    },
    "invoices": {
        "search": "Пошук",
        "issuedInvoices": "Оформлені інвойси",
        "customer": "Замовник",
        "admissionDate": "Дата прийняття",
        "endDate": "Дата завершення",
        "translationForm": "Форма перекладу",
        "price": "Сума",
        "status": "Статус",
        "downloadAnInvoice": "Завантажити інвойс",
        "admitted": "Прийнято"
    },
    "inquiries": {
        "today": "Сьогодні о(б)",
        "to": "До",
        "search": "Пошук",
        "ordersFrom": "Замовлення від",
        "messages": "Повідомлення",
        "date": "Дата",
        "chat": {
            "language": "Мова",
            "formOfTranslation": "Форма",
            "typeOfTranslation": "Вид",
            "deadline": "Термін до",
            "originalDocumentDelivery": " Спосіб надання оригіналу",
            "receiptOfTranslation": "Спосіб отримання перекладу",
            "paymentMethod": "Спосіб платежу",
            "methodOfCalculatingId": "Спосіб розрахунку вартості послуги",
            "currency": "Валюта",
            "typeOfRate": "Вид ставки",
            "grossRate": "Вартість за од. (злотих з ПДВ)",
            "vatRate": "Ставка ПДВ",
            "expiryDate": "Термін дії пропозиції",
            "orderFrom": "Замовлення від",
            "methodOfCalculating": "Спосіб розрахунку вартості послуги",
            "priceNet": "Вартість за од. (злотих без ПДВ)",
            "priceGross": "Вартість за од. (злотих з ПДВ)",
            "net": "без ПДВ",
            "gross": "з ПДВ",
            "successMakeOffer": "Пропозицію надіслано успішно",
            "failMakeOffer": "Помилка створення пропозиції",
            "successEditOffer": "Пропозицію редаговано успішно",
            "failEditOffer": "Помилка редагування пропозиції",
            "successAcceptOffer": "Пропозицію успішно затверджено",
            "failAcceptOffer": "Помилка затвердження пропозиції",
            "address": "Адреса",
            "dates": "Дати",
            "date": "Дата",
            "timeTo": "До год.",
            "timeFrom": "Від год.",
            "successCloseInquiries": "Запит закрито успішно",
            "failCloseInquiries": "Помилка закриття запиту",
            "yes": "Так",
            "no": "Ні",
            "successEditInquiries": "Запит успішно відредагований",
            "failEditInquiries": "Помилка редагування запиту",
            "successRejectOfferAndCloseInquiries": "Успішно відхилено пропозицію і успшіно закрито запит",
            "failRejectOfferAndCloseInquiries": "Помилка відхилення пропозиції.",
            "button": {
                "cancel": "Скасувати",
                "makeOffer": "Надати пропозицію",
                "sendOffer": "Надіслати пропозицію",
                "edit": "Редагувати",
                "updates": "Оновити",
                "editOffer": "Редагувати",
                "acceptOffer": "Затвердити",
                "rejectAndCloseOffer": "Відхилити і закрити",
                "close": "Закрити",
                "rejectInquirie": "Відхилити запит",
                "sendMessage": "Надіслати повідомлення",
                "putMessage": "Написати повідомлення"
            },
            "confirm": {
                "rejectTitle": "Відхилити і закрити запит",
                "rejectDescription": "Ви впевнені, що хочете закрити запит?",
                "closeTitle": "Закрити запит",
                "closeDescription": " Ви впевнені, що хочете закрити запит?",
                "rejectAndAddToRepertory": "Чи хочете додати відхилений запит до реєстру?"
            },
            "validation": {
                "language": "Вказаної мови не існує",
                "formOfTranslation": "Вказаної форми не існує",
                "typeOfTranslation": "Вказаного виду перекладу не існує",
                "failSendFile": "Помилка надсилання файлів",
                "checkExtensions": "Перевірте розширення файлів"
            }
        }
    },
    "notFoundPage": {
        "error404": "Помилка 404",
        "pageNotExist": "Ця сторінка відсутня",
        "backToHomePage": "Повернутися на головну",
        "pageNotFoundIconAlt": "Іконка помилки 404"
    },
    "modal": {
        "close": "Закрити"
    },
    "autocomplete": {
        "loading": "Вантажиться...",
        "noResult": "Результати відсутні"
    },
    "loginAndRegistrationView": {
        "title": "Увійти або зареєструватися",
        "login": "Вхід",
        "registration": "Реєстрація",
        "eyeIconAlt": "Іконка ока",
        "showPassword": "Показати пароль",
        "hidePassword": "Приховати пароль"
    },
    "login": {
        "email": "Адреса електронної пошти",
        "password": "Пароль",
        "loginButton": "Увійти",
        "forgotPassword": "Я забув пароль…"
    },
    "registration": {
        "accountActivatedAlt": "Іконка підтвердження",
        "customerOrCompany": "Індивідуальний клієнт або компанія",
        "publicInstitution": "Державна установа",
        "swornTranslator": "Присяжний перекладач",
        "selectIconAlt": "Іконка вибору",
        "email": "Адреса електронної пошти",
        "businessEmail": "Службова адреса електронної пошти",
        "businessPhone": "Службовий телефон",
        "institutionName": "Організація (точне найменування)",
        "institutionWebsite": "Адреса веб-сайту установи",
        "position": "Посада",
        "name": "Ім’я",
        "surname": "Прізвище",
        "password": "Пароль",
        "translatorNumber": "Вкажіть ваш номер перекладача (TP/1234/00)",
        "regulations_acceptance_start": "Я погоджуюсь з ",
        "regulations_acceptance_link": "регламентом сайту",
        "regulations_acceptance_end": "і даю свою згоду на обробку персональних даних...",
        "signUpButton": "Зареєструватися",
        "next": "Далі",
        "confirmPhone": "Підтвердьте свій номер телефону",
        "confirmationByLetter": "Дякуємо! Очікуйте листа від нас",
        "confirmEmail": "Підтвердьте свою адресу електронної пошти",
        "confirmEmailInfo": "Ми надіслали вам повідомлення. Перейдіть за посиланням з повідомлення, щоб закінчити реєстрацію.",
        "confirmTranslatorEmailInfo": " Ми надіслали вам повідомлення на адресу електронної пошти, вказану у списку присяжних перекладачів Міністерства юстиції:",
        "clickLinkToRegister": "Клацніть посилання з повідомлення, щоб закінчити реєстрацію.",
        "confirmTranslatorPhoneInfo": "Ми надіслали вам SMS-повідомлення з кодом перевірки на номер телефону, вказаний у базі присяжних перекладачів Міністерства юстиції:",
        "confirmTranslatorLetterInfo": "Протягом декількох днів ми надішлемо на Вашу поштову адресу з бази присяжних перекладачів Міністерства юстиції рекомендований лист з кодом перевірки.",
        "linkSentIconAlt": "Іконка надсилання повідомлення",
        "accountActivated": "Обліковий запис активовано",
        "goToYourAccount": "Перейти у свій обліковий запис",
        "verificationError": "Помилка перевірки",
        "sendNewLink": "Надіслати нове посилання",
        "sending": "Надсилання...",
        "backToHomePage": "Повернутися на головну",
        "goFurther": "Перейти далі",
        "enterEmail": "Впишіть свою адресу електронної пошти",
        "translatorVerificationButton": "У вас вже є код перевірки?",
        "translatorVerification": {
            "verifyAndFillData": "Перевірте і доповніть дані",
            "enterTpNumber": "Введіть код перекладача",
            "enterVerificationCode": "Введіть код перевірки",
            "verificationCode": "Код перевірки",
            "enterEmail": "Впишіть свою адресу електронної пошти"
        }
    },
    "forgotPassword": {
        "title": "Вкажіть адресу електронної пошти для зміни паролю",
        "email": "Електронна пошта",
        "enterEmail": "Введіть свою адресу електронної пошти",
        "backToLogin": "Повернутися до сторінки входу",
        "next": "Далі",
        "linkSentTitle": "Перевірте пошту, щоб змінити пароль",
        "linkSentIconAlt": "Іконка надсилання повідомлення",
        "linkSentInfo": "Ми надіслали вам повідомлення. Клацніть посилання в повідомленні, щоб змінити пароль."
    },
    "changePassword": {
        "title": "Вкажіть новий пароль",
        "email": "Адреса електронної пошти",
        "enterEmail": "Вкажіть свою адресу електронної пошти",
        "password": "Пароль",
        "changePasswordButton": "Змінити пароль",
        "passwordChanged": "Ваш пароль змінено"
    },
    "translatorProfile": {
        "absence": {
            "absenceAtWork": " Перерва в роботі",
            "setAbsenceInWork": "Ustaw przerwę w pracy",
            "dateFrom": "З дня",
            "dateTo": "до дня",
            "indefinitely": "Безтерміново",
            "messageForClients": "Повідомлення для клієнтів",
            "save": "Зберегти",
            "successDisableAbsence": "Перерву успішно вимкнено",
            "successSetAbsence": " Перерву успішно увімкнено",
            "valid": {
                "dateGreaterThan": "Дата не може бути ранішою за дату з дня"
            }
        },
        "breakAtWork": "Перерва в роботі"
    },
    "tabs": {
        "hello": "Ласкаво просимо",
        "requests": "Запитання",
        "orders": "Замовлення",
        "invoice": "Рахунки",
        "repertory": "Реєстр",
        "profile": "Обліковий запис",
        "settings": "Налаштування",
        "jobs": "Замовлення"
    },
    "validation": {
        "email": "Вказана адреса електронної пошти неправильна",
        "required": "Необхідно вказати",
        "password": "Пароль має містити: мін. 8 символів, мін. 1 велика літера, мін. 1 мала літера, мін. 1 спецсимвол",
        "maxFileSize": "Файл {fileName} надто великий",
        "maximumFiles": "Максимальна кількість файлів: {maxFiles}",
        "maximumSize": "Сумарний максимальний розмір файлів: {maximumSize}",
        "passwordConfirmation": "Паролі мають співпадати",
        "max_nip": "Податковий номер NIP мусить мати 10 цифр"
    },
    "errorCodes": {
        "2": "Токен неправильний",
        "3": "Токен неправильний",
        "4": "Токен неправильний",
        "5": "Обілковий запис заблоковано",
        "6": "Неправильна назва користувача або неправильний пароль ",
        "7": "Електронну пошту не підтверджено",
        "8": "Надіслано дворівневий код",
        "9": "Користувач вже існує",
        "10": "Помилка. Прохання спробувати ще раз пізнішне.",
        "14": "Електронну пошту вже підтверджено",
        "15": "Користувача видалено",
        "default": "Під час обробки задачі сталася помилка."
    },
    "languageSettings": {
        "interpreting": "усний переклад",
        "deadline": "Термін",
        "choosePreferredServices": "Вибрати бажані послуги",
        "dateFrom": "від"
    },
    "profileSettings": {
        "aboutMe": "Про мене",
        "save": "Зберегти",
        "saving": "Збереження...",
        "edit": "Редагувати",
        "cancel": "Скасувати",
        "change": "Змінити",
        "delete": "Видалити",
        "deleting": "Видалення...",
        "add": "Додати",
        "yourPhoto": "Ваша світлина",
        "profilePage": "Основна світлина",
        "addFilePlaceholder": "Вибрати файл",
        "choseFile": "Вибрано",
        "experience": {
            "title": "Досвід",
            "companyName": "Компанія",
            "location": "Розташування",
            "position": "Посада",
            "workStart": "Початок роботи",
            "workEnd": "Кінець роботи",
            "workingHereCurrently": "Працюю тут зараз",
            "currently": "зараз"
        },
        "education": {
            "title": "Освіта",
            "school": "Школа",
            "specialization": "Спеціалізація",
            "academicDegree": "Науковий ступінь",
            "workStart": "Початок",
            "workEnd": "Завершення (або плановане завершення)"
        }
    },
    "settings": {
        "save": "Зберегти",
        "saving": "Збереження...",
        "edit": "Редагування",
        "cancel": "Скасувати",
        "change": "Змінити",
        "delete": "Видалити",
        "deleting": "Видалення...",
        "add": "Додати",
        "arrowIconAlt": "Іконка стрілки",
        "addresses": {
            "title": "Адреси",
            "lockedAddress": "Адреса заблокована",
            "lockIconAlt": "Іконка замка",
            "defaultAddress": "Адреса за замовчуванням",
            "setAsDefaultAddress": "Встановити адресу за замовчуванням",
            "settingAsDefaultAddress": " Встановлення адреси за замовчуванням...",
            "street": "Вулиця",
            "buildingNumber": "Номер",
            "flatNumber": "Приміщення",
            "city": "Місто",
            "postcode": "Індекс"
        },
        "emails": {
            "title": "Адреси електронної пошти",
            "lockedEmail": "Електронну пошту заблоковано",
            "lockIconAlt": "Іконка замка",
            "defaultEmail": "Електронна пошта за замовчуванням",
            "setAsDefaultEmail": "Встановити адресу за замовчуванням",
            "settingAsDefaultEmail": "Встановлення адреси за замовчуванням...",
            "email": "Електронна пошта"
        },
        "clientEmail": {
            "title": "Адреса електронної пошти",
            "email": "Електронна пошта"
        },
        "phones": {
            "title": "Телефони",
            "lockedPhone": "Телефон заблоковано",
            "lockIconAlt": "Іконка замка",
            "defaultPhone": "Телефон за замовчуванням",
            "setAsDefaultPhone": "Встановити телефон за замовчуванням",
            "settingAsDefaultPhone": "Встановлення телефону за замовчуванням...",
            "phone": "Телефон",
            "phonePrefix": "Код",
            "isLandline": "Міський телефон"
        },
        "invoicesData": {
            "title": "Ваші дані до інвойсу Twoje dane do faktury",
            "street": "Вулиця",
            "buildingNumber": "Номер",
            "flatNumber": "Приміщення",
            "city": "Населений пункт",
            "postcode": "Індекс",
            "name": "Назва",
            "nip": "Податковий номер NIP",
            "postOffice": "Пошта",
            "iAmVatPayer": "Я – платник ПДВ",
            "basicNumeration": "Основна нумерація",
            "courtsProsecutorsPoliceNumeration": "Нумерація суди, прокуратура, поліція",
            "prefix": "Префікс",
            "numerationType": "Вид нумерації",
            "format": "Формат",
            "startFrom": "Починати від",
            "registeredInBdo": "Фірма зареєстрована в BDO",
            "bdoNumber": "BDO-номер",
            "bankAccountsNumbers": "Номери банківських рахунків",
            "currency": "Валюта",
            "country": "Країна",
            "bankAccountNumber": "Номер",
            "kindNumberingTypes": {
                "1": "Річна",
                "2": "Місячна"
            },
            "defaultInvoiceData": "Дані за замовчуванням",
            "setAsDefaultInvoiceData": "Встановити за замовчуванням",
            "settingAsDefaultInvoiceData": "Встановлення за замовчуванням..."
        },
        "removeAccount": {
            "title": "Видалення облікового запису",
            "confirmation": "Підтверджую бажання видалити обліковий запис",
            "removed": "Обліковий запис видалено",
            "cancelButton": "Скасувати",
            "confirmButton": "Підтвердити",
            "confirmModalTitle": "Підтвердьте видалення облікового запису",
            "confirmModalDescription": "Видаленого облікового запису не можна повторно активувати.<br/> Після підтвердження відбудеться автоматичний вихід із застосунку."
        },
        "subscription": {
            "title": "Ваша підписка",
            "showYourSubscription": "Показати деталі підписки",
            "to": "до",
            "validFrom": "Дійсна від",
            "validTo": "Дійсна до",
            "subscriptionId": "ID підписки"
        },
        "changePassword": {
            "title": "Змінити пароль",
            "currentPassword": "Поточний пароль",
            "newPassword": "Новий пароль",
            "repeatPassword": "Повторити пароль",
            "passwordChanged": "Пароль змінено"
        }
    },
    "months": {
        "1": "Січень",
        "2": "Лютий",
        "3": "Березень",
        "4": "Квітень",
        "5": "Травень",
        "6": "Червень",
        "7": "Липень",
        "8": "Серпень",
        "9": "Вересень",
        "10": "Жовтень",
        "11": "Листопад",
        "12": "Грудень"
    },
    "repertory": {
        "itemsNeedCompleted": "Рядки, що вимагають доповнення",
        "completedItems": "Доповнено",
        "adoptionDate": "Дата прийняття",
        "refusalDate": "Дата відмови",
        "ordinalNumber": "№ з/п (1)",
        "orderingDesignation": "Назва замовника",
        "adoptionOrderDate": "Дата прийняття замовлення (2)",
        "orderingDesignationOrPurchaser": "Назва замовника вказаного перекладу (3)",
        "translationForm": "Форма перекладу",
        "descriptionTranslatedDocument": "Опис документу, що перекладається",
        "nameDateAndDesignation": "Назва, дата і № документу (4)",
        "languageDocumentDrawek": "Мова, якою буде оформлений документ (5)",
        "commentsOnType": "Зауваження про вид, форму і стан документу (7)",
        "activityDesignation": "Позначення дії",
        "typeOfActivityPerformed": "Вид дії та мова перекладу (8)",
        "numberOfPagesOfTranslation": "Кількість сторінок перекладу (9)",
        "numberOfCopiesMade": "Кількість оформлених примірників (10)",
        "refusalToProvideTranslation": "Відмова у виконанні перекладу суб’єктам, про яких мова йде у ст. 15 закону про професію присяжного перекладача",
        "authorityRequestingTranslation": "Орган, що вимагає перекладу (17)",
        "reasonForRefusalTranslation": "Причина відмови (18)",
        "remarks": "Примітки",
        "interpretationDescription": "Опис усного перекладу",
        "date": "Дата",
        "place": "Місце (11)",
        "duration": "Тривалість",
        "amountCollectedRemuneration": "Сума отриманої винагороди (14)",
        "dateOfReturnDocument": "Дата повернення документу разом з перекладом (15)",
        "range": "Діапазон",
        "edit": "Редагувати",
        "export": "Експортувати",
        "search": "Пошук"
    },
    metadata: {
        searchTranslator: {
            title: "Tłumacze przysięgli",
            description: "Tłumacze przysięgli",
        },
        home: {
            title: 'Znajdź tłumacza',
            description: 'Znajdź mi tłumacza przysięgłego',
        },
        findTranslator: {
            title: 'Znajdź tłumacza',
            description: 'Znajdź mi tłumacza przysięgłego',
        },
        forTranslators: {
            title: 'Dla tłumaczy przysięgłych',
            description: 'Jesteś tłumaczem? Zarejestruj się i aktywuj konto VIP, aby korzystać z wielu przydatnych funkcji',
        },
        // translatorPage: {
        //     title: 'Dla tłumaczy przysięgłych',
        //     description: 'Jesteś tłumaczem? Zarejestruj się i aktywuj konto VIP, aby korzystać z wielu przydatnych funkcji',
        // }
        login: {
            title: 'Logowanie',
            description: 'SwornApp - logowanie'
        },
        registration: {
            title: 'Rejestracja',
            description: 'SwornApp - rejestracja'
        },
        registrationEmailVerification: {
            title: 'Weryfikacja email',
            description: ''
        },
        registrationTranslatorVerification: {
            title: 'Weryfikacja tłumacza',
            description: ''
        },
        forgotPassword: {
            title: 'Zapomniałeś hasła?',
            description: 'Zapomniałeś hasła?'
        },
        changePassword: {
            title: 'Zmiana hasła',
            description: 'Zmień swoje hasło'
        },
        inquiries: {
            title: 'Zapytania',
            description: ''
        },
        orders: {
            title: 'Zamówienia',
            description: ''
        },
        invoices: {
            title: 'Faktury',
            description: ''
        },
        profileSettings: {
            title: 'Ustawienia profilu',
            description: ''
        },
        accountSettings: {
            title: 'Ustawienia konta',
            description: ''
        },
    }
}
