export const STATUS_INCOMPLETE = 'incomplete'
export const STATUS_COMPLETED = 'completed'
export const STATUS = [
    STATUS_INCOMPLETE,
    STATUS_COMPLETED
]


export const TYPE_WRITTEN = 1
export const TYPE_WRITTEN_REJECTED = 2
export const TYPE_INTERPRETING = 3
export const TYPE_INTERPRETING_REJECTED = 4

export const REJECTED_TYPES = [
    TYPE_WRITTEN_REJECTED,
    TYPE_INTERPRETING_REJECTED,
]
export const WRITTEN_TYPES = [
    TYPE_WRITTEN,
    TYPE_WRITTEN_REJECTED,
]
export const INTERPRETING_TYPES = [
    TYPE_INTERPRETING,
    TYPE_INTERPRETING_REJECTED,
]


