import {RouteRecordRaw} from "vue-router";
import HomeView from "@/views/HomeView.vue";
import DefaultPage from "@/components/DefaultPage.vue";
import UserService from "@/services/userService";
import i18n from "@/i18n";

const routeTranslations = {
    translators: 'perevodchiki',
    forTranslators: 'dlia-perevodchikov',
    translator: 'perevodchik',
    login: 'vkhod',
    registration: 'registratsiia',
    verify: 'provierka',
    forgotPassword: 'zabyl-parol',
    changePassword: 'izmenit-parol',
    logout: 'vykhod',
    inquiries: 'zaprosy',
    orders: 'zakazy',
    invoices: 'invoisy',
    profileSettings: 'ustanovki-profila',
    accountSettings: 'ustanovki-akkaunta',
    chat: 'chat',
    repertory: 'reestr',
}

const routePrefix = 'ru';
i18n.global.locale = 'ru';
const routePaths = {
    home: '',
    findTranslator: routeTranslations.translators + '/:language/:city?/:types?',
    forTranslators: routeTranslations.forTranslators,
    translatorPage: routeTranslations.translator + '/:nameSlug/:tpNumberSlug',
    login: routeTranslations.login,
    registration: routeTranslations.registration,
    registrationEmailVerification: routeTranslations.registration + '/' + routeTranslations.verify + '/:userEmail',
    registrationTranslatorVerification: routeTranslations.registration + '/' + routeTranslations.translator + '/' + routeTranslations.verify + '/:tpNumber?',
    forgotPassword: routeTranslations.forgotPassword,
    changePassword: routeTranslations.changePassword + '/:userEmail',
    logout: routeTranslations.logout,
    inquiries: routeTranslations.inquiries,
    orders: routeTranslations.orders,
    invoices: routeTranslations.invoices,
    profileSettings: routeTranslations.profileSettings,
    accountSettings: routeTranslations.accountSettings,
    chat: routeTranslations.chat,
    repertories: routeTranslations.repertory,
};

const routes: Array<RouteRecordRaw> = [
    {
        path: '/' + routePrefix,
        component: DefaultPage,
        children: [
            {
                path: routePaths.home,
                name: routePrefix + '.home',
                meta: {
                    title: i18n.global.t('metadata.home.title'),
                    description: i18n.global.t('metadata.home.description'),
                },
                component: HomeView,
            },
            {
                path: routePaths.findTranslator,
                name: routePrefix + '.findTranslator',
                meta: {
                    title: i18n.global.t('metadata.findTranslator.title'),
                    description: i18n.global.t('metadata.findTranslator.description'),
                },
                component: () => import(/* webpackChunkName: "findTranslator" */ '../views/FindTranslator.vue'),
            },
            {
                path: routePaths.forTranslators,
                name: routePrefix + '.forTranslators',
                meta: {
                    title: i18n.global.t('metadata.forTranslators.title'),
                    description: i18n.global.t('metadata.forTranslators.description'),
                },
                component: () => import(/* webpackChunkName: "forTranslators" */ '../views/ForTranslators.vue'),
            },
            {
                path: routePaths.translatorPage,
                name: routePrefix + '.translatorPage',
                meta: {
                    title: i18n.global.t('metadata.translatorPage.title'),
                    description: i18n.global.t('metadata.translatorPage.description'),
                },
                component: () => import(/* webpackChunkName: "translatorPage" */ '../views/TranslatorPage.vue')
            },

            //AUTH ROUTES//
            {
                path: routePaths.login,
                name: routePrefix + '.login',
                meta: {
                    isRegistration: false,
                    title: i18n.global.t('metadata.login.title'),
                    description: i18n.global.t('metadata.login.description'),
                },
                component: () => import(/* webpackChunkName: "login" */ '../views/auth/LoginAndRegistrationView.vue')
            },
            {
                path: routePaths.registration,
                name: routePrefix + '.registration',
                meta: {
                    isRegistration: true,
                    title: i18n.global.t('metadata.registration.title'),
                    description: i18n.global.t('metadata.registration.description'),
                },
                component: () => import(/* webpackChunkName: "registration" */ '../views/auth/LoginAndRegistrationView.vue')
            },
            {
                path: routePaths.registrationEmailVerification,
                name: routePrefix + '.registrationEmailVerification',
                meta: {
                    isRegistration: true,
                    title: i18n.global.t('metadata.registrationEmailVerification.title'),
                    description: i18n.global.t('metadata.registrationEmailVerification.description'),
                },
                component: () => import(/* webpackChunkName: "registrationEmailVerification" */ '../views/auth/RegistrationEmailVerification.vue')
            },
            {
                path: routePaths.registrationTranslatorVerification,
                name: routePrefix + '.registrationTranslatorVerification',
                meta: {
                    isRegistration: true,
                    title: i18n.global.t('metadata.registrationTranslatorVerification.title'),
                    description: i18n.global.t('metadata.registrationTranslatorVerification.description'),
                },
                component: () => import(/* webpackChunkName: "registrationTranslatorVerification" */ '../views/auth/RegistrationTranslatorVerification.vue')
            },
            {
                path: routePaths.forgotPassword,
                name: routePrefix + '.forgotPassword',
                meta: {
                    title: i18n.global.t('metadata.forgotPassword.title'),
                    description: i18n.global.t('metadata.forgotPassword.description'),
                },
                component: () => import(/* webpackChunkName: "forgotPassword" */ '../views/auth/ForgotPasswordView.vue')
            },
            {
                path: routePaths.changePassword,
                name: routePrefix + '.changePassword',
                meta: {
                    title: i18n.global.t('metadata.changePassword.title'),
                    description: i18n.global.t('metadata.changePassword.description'),
                },
                component: () => import(/* webpackChunkName: "changePassword" */ '../views/auth/ChangePasswordView.vue')
            },
            {
                path: routePaths.logout,
                name: routePrefix + '.logout',
                component: {
                    beforeRouteEnter(to, from, next) {
                        const userService = new UserService();
                        userService.logout();
                    }
                }
            },

            //TRANSLATION PROFILE //
            {
                path: routePaths.inquiries,
                name: routePrefix + '.inquiries',
                meta: {
                    dashboard: true,
                    title: i18n.global.t('metadata.inquiries.title'),
                    description: i18n.global.t('metadata.inquiries.description'),
                },
                component: () => import(/* webpackChunkName: "inquiries" */ '../views/InquiriesPage.vue')
            },
            {
                path: routePaths.orders,
                name: routePrefix + '.orders',
                meta: {
                    dashboard: true,
                    title: i18n.global.t('metadata.orders.title'),
                    description: i18n.global.t('metadata.orders.description'),
                },
                component: () => import(/* webpackChunkName: "orders" */ '../views/OrdersPage.vue')
            },
            {
                path: routePaths.invoices,
                name: routePrefix + '.invoices',
                meta: {
                    dashboard: true,
                    title: i18n.global.t('metadata.invoices.title'),
                    description: i18n.global.t('metadata.invoices.description'),
                },
                component: () => import(/* webpackChunkName: "invoices" */ '../views/InvoicesPage.vue')
            },
            {
                path: routePaths.repertories,
                name: routePrefix + '.repertories',
                meta: {
                    dashboard: true,
                    title: i18n.global.t('metadata.repertories.title'),
                    description: i18n.global.t('metadata.repertories.description'),
                },
                component: () => import(/* webpackChunkName: "repertories" */ '../views/RepertoriesPage.vue')
            },
            {
                path: routePaths.profileSettings,
                name: routePrefix + '.profileSettings',
                meta: {
                    dashboard: true,
                    title: i18n.global.t('metadata.profileSettings.title'),
                    description: i18n.global.t('metadata.profileSettings.description'),
                },
                component: () => import(/* webpackChunkName: "profileSettings" */ '../views/translator/TranslatorProfileSettings.vue')
            },
            {
                path: routePaths.accountSettings,
                name: routePrefix + '.accountSettings',
                meta: {
                    dashboard: true,
                    title: i18n.global.t('metadata.accountSettings.title'),
                    description: i18n.global.t('metadata.accountSettings.description'),
                },
                component: () => import(/* webpackChunkName: "accountSettings" */ '../views/AccountSettings.vue')
            },
            {
                path: routePaths.chat,
                name: routePrefix + '.chat',
                meta: {
                    dashboard: true,
                },
                component: () => import(/* webpackChunkName: "translatorPage" */ '../views/translator/ChatPage.vue')
            },
        ]
    },
];

export default routes;