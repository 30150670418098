export default function (title,description) {

    const updateMetaDescription = () => {
        const metaTag = document.querySelector('meta[property="og:description"]')
        if (metaTag)
            metaTag.setAttribute('content', description);

        document.title = title ? String(title): 'SwornApp';

        const metaTagOg = document.querySelector('meta[property="og:description"]');
        const titleOg = document.querySelector('meta[property="og:title"]');

        if(metaTagOg)
            metaTagOg.setAttribute('content', String(description));

        if(titleOg)
            titleOg.setAttribute('content', String(title));

    }

    return {
        updateMetaDescription,
    }
}