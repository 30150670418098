import i18n, {getLangConfig} from '../i18n'
import router, {getRouteName} from "@/router";
import axios from "axios";
import {
    TYPE_INTERPRETING,
    TYPE_INTERPRETING_REJECTED,
    TYPE_WRITTEN,
    TYPE_WRITTEN_REJECTED
} from "@/helpers/repertoriesHelper";

export const API_HELPERS =  {
    endpoints: {
        backendUrl: 'https://ops.sworn.online/',
        // backendUrl: 'https://api.sworn.app/',
        search: {
            getTranslators: 'v1/{culture}/search-module/translators/{language}', //Zwracamy wszystkie pasujące wyniki w postaci Id tłumacza + jego współrzędne, potrzebne do osadzenia pinesek na mapie. Tablica przychodzi już odpowiednio posortowana.
            postTranslator: 'v1/{culture}/search-module/translators', //W body przesyłamy tablicę id tłumaczy, dla których potrzebujemy pobrać szczegóły do pinezek czy do pokazania kolejnych wyników na liście wyszukanych tłumaczy.
            getOneTranslator: 'v1/{culture}/search-module/translator/{id}', //Zwraca rozbudowany szczegóły potrzebne do wyświetlenia popup/strony dotyczącej konkretnego tłumacza.
            getTranslatorBasic: 'v1/{culture}/search-module/translator/basic',
            getAddressLookup: 'v1/{culture}/search-module/address-lookup',
            getAddressLookupLocation: 'v1/{culture}/search-module/address-lookup/{id}/location',
            //FILTRY//
            availableLanguages: 'v1/{culture}/search-module/languages', //Zwraca słownik dostępnych języków dla zadanego culture
            availableTypesOfTranslations: 'v1/{culture}/search-module/types-of-translation', //Zwraca słownik rodzajów tłumaczeń
            availableLocations: 'v1/{culture}/search-module/frequently-searched-locations',
            //////////
            frequentlySearchedTranslators: 'v1/{culture}/search-module/frequently-searched-translators',
            translatorData: 'v1/{culture}/search-module/translator/{tpNumberSlug}/{nameSlug}', //Zwraca rozbudowany szczegóły potrzebne do wyświetlenia popup/strony dotyczącej konkretnego tłumacza.
        },
        auth: {
            login: 'v1/auth/login',
            refresh: 'v1/auth/refresh',
            forgotPassword: 'v1/auth/recover',
            changePassword: 'v1/auth/recover/verify',
            verifyEmail: 'v1/auth/register/verify',
            resendEmailVerificationLink: 'v1/auth/register/resend/{email}',
            register: {
                personal: 'v1/{culture}/accounts-module/register/personal',
                institution: 'v1/{culture}/accounts-module/register/organization',
                tpNumberVerification: 'v1/{culture}/accounts-module/register/verification',
                translator: 'v1/{culture}/accounts-module/register/translator',
            }
        },
        account: {
            userInfo: 'v1/{culture}/accounts-module/info',
            changePassword: 'v1/auth/password',
            removeAccount: 'v1/{culture}/accounts-module/remove-account'
        },
        translator: {
            languages: {
                getLanguages: 'v1/{culture}/translators-module/{translatorId}/languages',
                getDeadlines: 'v1/{culture}/translators-module/{translatorId}/deadlines',
                updateDeadline: 'v1/{culture}/translators-module/{translatorId}/language/{languageId}/deadline',
                updateInterpretation: 'v1/{culture}/translators-module/{translatorId}/language/{languageId}/interpretation',
                updateTypeOfTranslation: 'v1/{culture}/translators-module/{translatorId}/language/{languageId}/type-of-translation/{typeOfTranslationId}',
            },
            experiences: {
                getExperiences: 'v1/{culture}/translators-module/{translatorId}/experiences',
                add: 'v1/{culture}/translators-module/{translatorId}/experience',
                details: 'v1/{culture}/translators-module/{translatorId}/experience/{experienceId}',
                update: 'v1/{culture}/translators-module/{translatorId}/experience/{experienceId}',
                delete: 'v1/{culture}/translators-module/{translatorId}/experience/{experienceId}',
            },
            educations: {
                getEducations: 'v1/{culture}/translators-module/{translatorId}/educations',
                add: 'v1/{culture}/translators-module/{translatorId}/education',
                details: 'v1/{culture}/translators-module/{translatorId}/education/{educationId}',
                update: 'v1/{culture}/translators-module/{translatorId}/education/{educationId}',
                delete: 'v1/{culture}/translators-module/{translatorId}/education/{educationId}',
            },
            addresses: {
                getAddresses: 'v1/{culture}/translators-module/{translatorId}/addresses',
                add: 'v1/{culture}/translators-module/{translatorId}/address',
                details: 'v1/{culture}/translators-module/{translatorId}/address/{addressId}',
                update: 'v1/{culture}/translators-module/{translatorId}/address/{addressId}',
                delete: 'v1/{culture}/translators-module/{translatorId}/address/{addressId}',
                setDefault: 'v1/{culture}/translators-module/{translatorId}/address/{addressId}/default',
            },
            emails: {
                getEmails: 'v1/{culture}/translators-module/{translatorId}/mails',
                add: 'v1/{culture}/translators-module/{translatorId}/mail',
                details: 'v1/{culture}/translators-module/{translatorId}/mail/{emailId}',
                update: 'v1/{culture}/translators-module/{translatorId}/mail/{emailId}',
                delete: 'v1/{culture}/translators-module/{translatorId}/mail/{emailId}',
                setDefault: 'v1/{culture}/translators-module/{translatorId}/mail/{emailId}/default',
            },
            phones: {
                getPhones: 'v1/{culture}/translators-module/{translatorId}/phones',
                getPrefixes: 'v1/{culture}/translators-module/{translatorId}/phone/codes',
                add: 'v1/{culture}/translators-module/{translatorId}/phone',
                details: 'v1/{culture}/translators-module/{translatorId}/phone/{phoneId}',
                update: 'v1/{culture}/translators-module/{translatorId}/phone/{phoneId}',
                delete: 'v1/{culture}/translators-module/{translatorId}/phone/{phoneId}',
                setDefault: 'v1/{culture}/translators-module/{translatorId}/phone/{phoneId}/default',
            },
            invoices: {
                getInvoicesData: 'v1/{culture}/invoices-module/invoice-datas',
                checkInvoiceData: 'v1/{culture}/invoices-module/invoice-datas/check/{currency}',
                setDefault: 'v1/{culture}/invoices-module/invoice-data/{invoiceDataId}/default',
                add: 'v1/{culture}/invoices-module/invoice-data',
                update: 'v1/{culture}/invoices-module/invoice-data/{invoiceDataId}',
                delete: 'v1/{culture}/invoices-module/invoice-data/{invoiceDataId}',

            },
            repertories: {
                getRepertoryInfo: 'v1/{culture}/repertories-module/configuration',
                updateRepertoryInfo: 'v1/{culture}/repertories-module/configuration',
            },
            getProfileBasicInfo:'v1/{culture}/translators-module/{translatorId}',
            updateAboutMe:'v1/{culture}/translators-module/{translatorId}/description',
            updatePhoto:'v1/{culture}/translators-module/{translatorId}/profile-avatar',
            deletePhoto:'v1/{culture}/translators-module/{translatorId}/profile-avatar',
            getParamsForSendRequestForTranslator: 'v1/{culture}/translators-module/{translatorId}/params',
            getAbsenceAtWork: 'v1/{culture}/translators-module/{translatorId}/absence'

        },
        client: {
            addresses: {
                getAddresses: 'v1/{culture}/accounts-module/addresses',
                add: 'v1/{culture}/accounts-module/address',
                details: 'v1/{culture}/accounts-module/address/{addressId}',
                update: 'v1/{culture}/accounts-module/address/{addressId}',
                delete: 'v1/{culture}/accounts-module/address/{addressId}',
            },
            invoicesData: {
                getInvoicesData: 'v1/{culture}/accounts-module/invoice-datas',
                add: 'v1/{culture}/accounts-module/invoice-data',
                details: 'v1/{culture}/accounts-module/invoice-data/{invoiceDataId}',
                update: 'v1/{culture}/accounts-module/invoice-data/{invoiceDataId}',
                delete: 'v1/{culture}/accounts-module/invoice-data/{invoiceDataId}',
            },
            phones: {
                getPhones: 'v1/{culture}/accounts-module/phones',
                getPrefixes: 'v1/{culture}/accounts-module/phone/codes',
                add: 'v1/{culture}/accounts-module/phone',
                details: 'v1/{culture}/accounts-module/phone/{phoneId}',
                update: 'v1/{culture}/accounts-module/phone/{phoneId}',
                delete: 'v1/{culture}/accounts-module/phone/{phoneId}',
            },
        },
        inquiries: {
            getAll: 'v1/{culture}/inquiry-module/inquiries',
            getInquiriesInbox:'v1/{culture}/inquiry-module/{inquiryId}/inbox/{inquiryInboxId}',
            getInquiriesInterpreting:'v1/{culture}/inquiry-module/{translatorId}/inquiry-interpreting',
            getInquiriesWritten:'v1/{culture}/inquiry-module/{translatorId}/inquiry-written',
            //Przedstawienie oferty klientowi, gdy forma jest pisemna - z poziomu tłumacza
            makeOfferWritten: 'v1/{culture}/inquiry-module/{inquiryId}/inbox/{inquiryInboxId}/written/offer',
            //Przedstawienie oferty klientowi, gdy forma jest ustna - z poziomu tłumacza
            makeOfferInterpreting: 'v1/{culture}/inquiry-module/{inquiryId}/inbox/{inquiryInboxId}/interpreting/offer',
            //Edytowanie oferty, gdy forma jest pisemna -z poziomu tłumacza
            editOfferWrittenByTranslator: 'v1/{culture}/inquiry-module/{inquiryId}/inbox/{inquiryInboxId}/written/offer/{offerId}',
            //Edytowanie oferty, gdy forma jest ustna -z poziomu tłumacza
            editOfferInterpretingByTranslator: 'v1/{culture}/inquiry-module/{inquiryId}/inbox/{inquiryInboxId}/interpreting/offer/{offerId}',
            //Edytowanie zapytania, gdy forma jest pisemna -z poziomu klienta
            editInquiriesWrittenByClient: 'v1/{culture}/inquiry-module/{inquiryId}/inbox/{inquiryInboxId}/written',
            //Edytowanie zapytania, gdy forma jest ustna -z poziomu klienta
            editInquiriesInterpretingByClient: 'v1/{culture}/inquiry-module/{inquiryId}/inbox/{inquiryInboxId}/interpreting',
            //Akceptacja oferty - z poziomu kleinta
            acceptOfferByClient: 'v1/{culture}/inquiry-module/{inquiryId}/inbox/{inquiryInboxId}/offer/{offerId}/accept',
            //Odrzucenie oferty - z poziomu klienta
            rejectOfferByClient: 'v1/{culture}/inquiry-module/{inquiryId}/inbox/{inquiryInboxId}/offer/{offerId}/reject',
            //Odrzucenie i zamknięcie oferty z poziomu tlumacza
            rejectAndCloseOfferByTranslator: 'v1/{culture}/inquiry-module/{inquiryId}/inbox/{inquiryInboxId}/discard-and-close',
            //Zamknięcie oferty - chyba przez klienta
            closeOfferByClient: 'v1/{culture}/inquiry-module/{inquiryId}/inbox/{inquiryInboxId}/close',
            //Dodanie nowych załączników do oferty - z poziomu tlumacza i klienta
            addAttachmentToInquirie: 'v1/{culture}/inquiry-module/{inquiryId}/inbox/{inquiryInboxId}/attachment',
            //Usuwanie zalacznikow
            deleteAttachmentToInquirie: 'v1/{culture}/inquiry-module/{inquiryId}/inbox/{inquiryInboxId}/attachment/{attachmentId}',
            params: {
                getCurrencies: 'v1/{culture}/translators-module/{translatorId}/currencies',
                getCalculatingMethod: 'v1/{culture}/translators-module/{translatorId}/calculating-methods',
                getVatRates: 'v1/{culture}/translators-module/{translatorId}/vat-rates',

            }
        },
        orders: {
            getAll: 'v1/{culture}/orders-module/orders',
            getOrderDetails: 'v1/{culture}/orders-module/order/{orderId}',
            getOrderAdditionalDetails: 'v1/{culture}/orders-module/order/{orderId}/details',
            markAsPaid: 'v1/{culture}/orders-module/order/{orderId}/mark-as-paid',
            reject: 'v1/{culture}/orders-module/order/{orderId}/reject',
            endOrder: 'v1/{culture}/orders-module/order/{orderId}/complete',
        },
        invoices: {
            getAll: 'v1/{culture}/invoices-module/invoices',
            getFile: 'v1/{culture}/invoices-module/invoice/{invoiceId}/file',
        },
        repertories: {
            getIncompleteRepertories: 'v1/{culture}/repertories-module/repertories/incompleted',
            getCompletedRepertories: 'v1/{culture}/repertories-module/repertories/completed',
            getRepertoryDetails: 'v1/{culture}/repertories-module/repertory/{repertoryId}',
            updateInterpretingRepertory: 'v1/{culture}/repertories-module/repertory/{repertoryId}/interpreting',
            updateInterpretingRejectedRepertory: 'v1/{culture}/repertories-module/repertory/{repertoryId}/interpreting-rejected',
            updateWrittenRepertory: 'v1/{culture}/repertories-module/repertory/{repertoryId}/written',
            updateWrittenRejectedRepertory: 'v1/{culture}/repertories-module/repertory/{repertoryId}/written-rejected',
        },
        files: {
            getFile: "v1/file-storage-module/{fileId}",
            addFile: 'v1/file-storage-module',
            removeFile: "v1/file-storage-module/{fileId}",
            getSupportedFormat: 'v1/file-storage-module/supported-formats',
        }
    }
}

const API_CODES = [
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "200",
    "400",
    "401",
    "403",
    "404",
    "500",
    "invoice_data_not_found_request",
];


export default class ApiHelper {

    static getFrequentlySearchedTranslatorsUrl() {
        let url = API_HELPERS.endpoints.search.frequentlySearchedTranslators;
        url = url.replace('{culture}', getLangConfig());

        return API_HELPERS.endpoints.backendUrl + url;
    }

    static getTranslatorDataUrl(nameSlug: string, tpNumberSlug: string) {
        let url = API_HELPERS.endpoints.search.translatorData;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{nameSlug}', nameSlug);
        url = url.replace('{tpNumberSlug}', tpNumberSlug);

        return API_HELPERS.endpoints.backendUrl + url;
    }

    static getTranslatorBasicDataUrl(tpNumber: string) {
        let url = API_HELPERS.endpoints.search.getTranslatorBasic;
        url = url.replace('{culture}', getLangConfig());
        return API_HELPERS.endpoints.backendUrl + url + `?tpNumber=${tpNumber}`;
    }

    static getAvailableLanguagesUrl() {
        let url = API_HELPERS.endpoints.search.availableLanguages;
        url = url.replace('{culture}', getLangConfig());

        return API_HELPERS.endpoints.backendUrl + url;
    }

    static getAvailableTypesOfTranslationsUrl() {
        let url = API_HELPERS.endpoints.search.availableTypesOfTranslations;
        url = url.replace('{culture}', getLangConfig());

        return API_HELPERS.endpoints.backendUrl + url;
    }

    static getAvailableLocationsUrl() {
        let url = API_HELPERS.endpoints.search.availableLocations;
        url = url.replace('{culture}', getLangConfig());

        return API_HELPERS.endpoints.backendUrl + url;
    }

    static getTranslators(language: number) :string {
        let url: string = API_HELPERS.endpoints.search.getTranslators;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{language}', String(language));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getAddressLookup(term: string) {
        let url: string = API_HELPERS.endpoints.search.getAddressLookup + `?input=${term}`;
        url = url.replace('{culture}', getLangConfig());
        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getAddressLookupLocation(id: string) {
        let url: string = API_HELPERS.endpoints.search.getAddressLookupLocation;
        url = url.replace('{id}', id);
        url = url.replace('{culture}', getLangConfig());

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    //AUTH//
    static getLoginUrl() {
        return API_HELPERS.endpoints.backendUrl + API_HELPERS.endpoints.auth.login;
    }

    static getRefreshTokenUrl() {
        return API_HELPERS.endpoints.backendUrl + API_HELPERS.endpoints.auth.refresh;
    }

    static getPersonalRegistrationUrl() {
        let url: string = API_HELPERS.endpoints.auth.register.personal;
        url = url.replace('{culture}', getLangConfig());
        return API_HELPERS.endpoints.backendUrl + url;
    }
    static getInstitutionRegistrationUrl() {
        let url: string = API_HELPERS.endpoints.auth.register.institution;
        url = url.replace('{culture}', getLangConfig());
        return API_HELPERS.endpoints.backendUrl + url;
    }
    static getTranslatorTpNumberVerificationUrl() {
        let url: string = API_HELPERS.endpoints.auth.register.tpNumberVerification;
        url = url.replace('{culture}', getLangConfig());
        return API_HELPERS.endpoints.backendUrl + url;
    }
    static getTranslatorRegistrationUrl() {
        let url: string = API_HELPERS.endpoints.auth.register.translator;
        url = url.replace('{culture}', getLangConfig());
        return API_HELPERS.endpoints.backendUrl + url;
    }
    static getForgotPasswordUrl() {
        return API_HELPERS.endpoints.backendUrl + API_HELPERS.endpoints.auth.forgotPassword;
    }
    static getChangePasswordUrl() {
        return API_HELPERS.endpoints.backendUrl + API_HELPERS.endpoints.auth.changePassword;
    }
    static getVerifyEmailUrl() {
        return API_HELPERS.endpoints.backendUrl + API_HELPERS.endpoints.auth.verifyEmail;
    }
    static getResendEmailVerificationLinkUrl(email) {
        let url: string = API_HELPERS.endpoints.auth.resendEmailVerificationLink;
        url = url.replace('{email}', email);
        return API_HELPERS.endpoints.backendUrl + url;
    }
    //END AUTH//

    //TRANSLATOR
    static getParamsForSendRequestForTranslator(translatorId) {
        let url: string = API_HELPERS.endpoints.translator.getParamsForSendRequestForTranslator;
        url = url.replace('{translatorId}', translatorId);
        url = url.replace('{culture}', getLangConfig());

        return API_HELPERS.endpoints.backendUrl + url;
    }

    static getIncompleteRepertories() {
        let url: string = API_HELPERS.endpoints.repertories.getIncompleteRepertories;
        url = url.replace('{culture}', getLangConfig());

        return API_HELPERS.endpoints.backendUrl + url;
    }

    static getCompletedRepertories() {
        let url: string = API_HELPERS.endpoints.repertories.getCompletedRepertories;
        url = url.replace('{culture}', getLangConfig());

        return API_HELPERS.endpoints.backendUrl + url;
    }

    static getRepertoryDetails(id) {
        let url: string = API_HELPERS.endpoints.repertories.getRepertoryDetails;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{repertoryId}', String(id));

        return API_HELPERS.endpoints.backendUrl + url;
    }

    static getUpdateRepertory(id, type) {
        let url = '';
        if (type == TYPE_WRITTEN)
            url = API_HELPERS.endpoints.repertories.updateWrittenRepertory;
        else if (type == TYPE_WRITTEN_REJECTED)
            url = API_HELPERS.endpoints.repertories.updateWrittenRejectedRepertory;
        else if (type == TYPE_INTERPRETING)
            url = API_HELPERS.endpoints.repertories.updateInterpretingRepertory;
        else if (type == TYPE_INTERPRETING_REJECTED)
            url = API_HELPERS.endpoints.repertories.updateInterpretingRejectedRepertory;

        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{repertoryId}', String(id));

        return API_HELPERS.endpoints.backendUrl + url;
    }

    static getAllOrders() {
        let url: string = API_HELPERS.endpoints.orders.getAll;
        url = url.replace('{culture}', getLangConfig());

        return API_HELPERS.endpoints.backendUrl + url;
    }

    static getOrderDetails(orderId) {
        let url: string = API_HELPERS.endpoints.orders.getOrderDetails;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{orderId}', String(orderId));

        return API_HELPERS.endpoints.backendUrl + url;
    }

    static getOrderAdditionalDetailsUrl(orderId) {
        let url: string = API_HELPERS.endpoints.orders.getOrderAdditionalDetails;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{orderId}', String(orderId));

        return API_HELPERS.endpoints.backendUrl + url;
    }

    static getMarkOrderAsPaidUrl(orderId) {
        let url: string = API_HELPERS.endpoints.orders.markAsPaid;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{orderId}', String(orderId));

        return API_HELPERS.endpoints.backendUrl + url;
    }

    static getRejectOrderUrl(orderId) {
        let url: string = API_HELPERS.endpoints.orders.reject;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{orderId}', String(orderId));

        return API_HELPERS.endpoints.backendUrl + url;
    }

    static getEndOrderUrl(orderId) {
        let url: string = API_HELPERS.endpoints.orders.endOrder;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{orderId}', String(orderId));

        return API_HELPERS.endpoints.backendUrl + url;
    }

    static getAllInvoices() {
        let url: string = API_HELPERS.endpoints.invoices.getAll;
        url = url.replace('{culture}', getLangConfig());

        return API_HELPERS.endpoints.backendUrl + url;
    }

    static getInvoiceFile(invoiceId) {
        let url: string = API_HELPERS.endpoints.invoices.getFile;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{invoiceId}',invoiceId);

        return API_HELPERS.endpoints.backendUrl + url;
    }

    static getTranslatorProfileLanguagesUrl(translatorId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.languages.getLanguages;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorProfileLanguageDeadlinesUrl(translatorId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.languages.getDeadlines;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorProfileLanguageUpdateDeadlineUrl(translatorId: string, languageId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.languages.updateDeadline;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));
        url = url.replace('{languageId}', String(languageId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorProfileLanguageUpdateInterpretationUrl(translatorId: string, languageId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.languages.updateInterpretation;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));
        url = url.replace('{languageId}', String(languageId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorProfileLanguageUpdateTypeOfTranslationUrl(translatorId: string, languageId: string, typeOfTranslationId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.languages.updateTypeOfTranslation;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));
        url = url.replace('{languageId}', String(languageId));
        url = url.replace('{typeOfTranslationId}', String(typeOfTranslationId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorProfileBasicInfoUrl(translatorId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.getProfileBasicInfo;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorProfileUpdateAboutMeUrl(translatorId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.updateAboutMe;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorProfileUpdatePhotoUrl(translatorId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.updatePhoto;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorProfileDeletePhotoUrl(translatorId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.deletePhoto;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorProfileExperiencesUrl(translatorId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.experiences.getExperiences;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorProfileAddExperienceUrl(translatorId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.experiences.add;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorProfileUpdateExperienceUrl(translatorId: string, experienceId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.experiences.update;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));
        url = url.replace('{experienceId}', String(experienceId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorProfileDeleteExperienceUrl(translatorId: string, experienceId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.experiences.delete;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));
        url = url.replace('{experienceId}', String(experienceId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorProfileEducationsUrl(translatorId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.educations.getEducations;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorProfileAddEducationUrl(translatorId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.educations.add;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorProfileUpdateEducationUrl(translatorId: string, educationId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.educations.update;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));
        url = url.replace('{educationId}', String(educationId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorProfileDeleteEducationUrl(translatorId: string, educationId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.educations.delete;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));
        url = url.replace('{educationId}', String(educationId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorSettingsAddressesUrl(translatorId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.addresses.getAddresses;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorSettingsAddAddressUrl(translatorId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.addresses.add;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorSettingsSetDefaultAddressUrl(translatorId: string, addressId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.addresses.setDefault;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));
        url = url.replace('{addressId}', String(addressId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorSettingsUpdateAddressUrl(translatorId: string, addressId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.addresses.update;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));
        url = url.replace('{addressId}', String(addressId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorSettingsDeleteAddressUrl(translatorId: string, addressId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.addresses.delete;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));
        url = url.replace('{addressId}', String(addressId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorSettingsGetRepertoryInfoUrl() :string {
        let url: string = API_HELPERS.endpoints.translator.repertories.getRepertoryInfo;
        url = url.replace('{culture}', getLangConfig());

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorSettingsUpdateRepertoryInfoUrl() :string {
        let url: string = API_HELPERS.endpoints.translator.repertories.updateRepertoryInfo;
        url = url.replace('{culture}', getLangConfig());

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorSettingsEmailsUrl(translatorId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.emails.getEmails;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorSettingsAddEmailUrl(translatorId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.emails.add;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorSettingsSetDefaultEmailUrl(translatorId: string, emailId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.emails.setDefault;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));
        url = url.replace('{emailId}', String(emailId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorSettingsUpdateEmailUrl(translatorId: string, emailId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.emails.update;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));
        url = url.replace('{emailId}', String(emailId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorSettingsDeleteEmailUrl(translatorId: string, emailId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.emails.delete;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));
        url = url.replace('{emailId}', String(emailId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorSettingsInvoicesDataUrl() :string {
        let url: string = API_HELPERS.endpoints.translator.invoices.getInvoicesData;
        url = url.replace('{culture}', getLangConfig());

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static checkTranslatorSettingsInvoiceDataUrl(currency: string) :string {
        let url: string = API_HELPERS.endpoints.translator.invoices.checkInvoiceData;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{currency}', String(currency));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorSettingsSetDefaultInvoiceDaraUrl(invoiceDataId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.invoices.setDefault;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{invoiceDataId}', String(invoiceDataId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorSettingsAddInvoiceDataUrl() :string {
        let url: string = API_HELPERS.endpoints.translator.invoices.add;
        url = url.replace('{culture}', getLangConfig());

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorSettingsUpdateInvoiceDataUrl(invoiceDataId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.invoices.update;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{invoiceDataId}', String(invoiceDataId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorSettingsDeleteInvoiceDataUrl(invoiceDataId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.invoices.delete;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{invoiceDataId}', String(invoiceDataId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorSettingsPhonesUrl(translatorId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.phones.getPhones;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorSettingsPhonePrefixesUrl(translatorId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.phones.getPrefixes;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorSettingsAddPhoneUrl(translatorId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.phones.add;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorSettingsSetDefaultPhoneUrl(translatorId: string, phoneId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.phones.setDefault;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));
        url = url.replace('{phoneId}', String(phoneId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorSettingsUpdatePhoneUrl(translatorId: string, phoneId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.phones.update;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));
        url = url.replace('{phoneId}', String(phoneId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getTranslatorSettingsDeletePhoneUrl(translatorId: string, phoneId: string) :string {
        let url: string = API_HELPERS.endpoints.translator.phones.delete;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));
        url = url.replace('{phoneId}', String(phoneId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getAbsenceAtWorkUrl(translatorId: string) {
        let url: string = API_HELPERS.endpoints.translator.getAbsenceAtWork;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', String(translatorId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    //END TRANSLATORS

    //INQUIRIES
    static getInquiriesInterpretingUrl(translatorId) {
        let url: string = API_HELPERS.endpoints.inquiries.getInquiriesInterpreting;
        url = url.replace('{translatorId}', translatorId);
        url = url.replace('{culture}', getLangConfig());

        return API_HELPERS.endpoints.backendUrl + url;
    }
    static getInquiriesInbox(inquiryId, inquiryInboxId) {
        let url: string = API_HELPERS.endpoints.inquiries.getInquiriesInbox;
        url = url.replace('{inquiryId}', inquiryId);
        url = url.replace('{inquiryInboxId}', inquiryInboxId);
        url = url.replace('{culture}', getLangConfig());

        return API_HELPERS.endpoints.backendUrl + url;
    }
    static getInquiriesWrittenUrl(translatorId) {
        let url: string = API_HELPERS.endpoints.inquiries.getInquiriesWritten;
        url = url.replace('{translatorId}', translatorId);
        url = url.replace('{culture}', getLangConfig());

        return API_HELPERS.endpoints.backendUrl + url;
    }
    static getAllInquiries() {
        let url: string = API_HELPERS.endpoints.inquiries.getAll;
        url = url.replace('{culture}', getLangConfig());

        return API_HELPERS.endpoints.backendUrl + url;
    }
    static getMakeOfferWrittenUrl(inquiryId, inquiryInboxId) {
        let url = API_HELPERS.endpoints.inquiries.makeOfferWritten;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{inquiryId}', inquiryId);
        url = url.replace('{inquiryInboxId}', inquiryInboxId);

        return API_HELPERS.endpoints.backendUrl + url;
    }
    static getMakeOfferInterpretingUrl(inquiryId, inquiryInboxId) {
        let url = API_HELPERS.endpoints.inquiries.makeOfferInterpreting;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{inquiryId}', inquiryId);
        url = url.replace('{inquiryInboxId}', inquiryInboxId);

        return API_HELPERS.endpoints.backendUrl + url;
    }
    static getEditOfferWrittenUrl(inquiryId, inquiryInboxId, offerId) {
        let url = API_HELPERS.endpoints.inquiries.editOfferWrittenByTranslator;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{inquiryId}', inquiryId);
        url = url.replace('{inquiryInboxId}', inquiryInboxId);
        url = url.replace('{offerId}', offerId);

        return API_HELPERS.endpoints.backendUrl + url;
    }
    static getEditOfferInterpretingUrl(inquiryId, inquiryInboxId, offerId) {
        let url :string = API_HELPERS.endpoints.inquiries.editOfferInterpretingByTranslator;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{inquiryId}', inquiryId);
        url = url.replace('{inquiryInboxId}', inquiryInboxId);
        url = url.replace('{offerId}', offerId);

        return API_HELPERS.endpoints.backendUrl + url;
    }
    static getEditInquiriesWrittenUrl(inquiryId, inquiryInboxId) {
        let url = API_HELPERS.endpoints.inquiries.editInquiriesWrittenByClient;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{inquiryId}', inquiryId);
        url = url.replace('{inquiryInboxId}', inquiryInboxId);

        return API_HELPERS.endpoints.backendUrl + url;
    }
    static getEditInquiriesInterpretingUrl(inquiryId, inquiryInboxId) {
        let url = API_HELPERS.endpoints.inquiries.editInquiriesInterpretingByClient;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{inquiryId}', inquiryId);
        url = url.replace('{inquiryInboxId}', inquiryInboxId);

        return API_HELPERS.endpoints.backendUrl + url;
    }
    static getAcceptOfferUrl(inquiryId, inquiryInboxId, offerId) {
        let url :string = API_HELPERS.endpoints.inquiries.acceptOfferByClient;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{inquiryId}', inquiryId);
        url = url.replace('{inquiryInboxId}', inquiryInboxId);
        url = url.replace('{offerId}', offerId);

        return API_HELPERS.endpoints.backendUrl + url;
    }
    static getRejectOfferInquiriesUrl(inquiryId, inquiryInboxId, offerId) {
        let url :string = API_HELPERS.endpoints.inquiries.rejectOfferByClient;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{inquiryId}', inquiryId);
        url = url.replace('{inquiryInboxId}', inquiryInboxId);
        url = url.replace('{offerId}', offerId);

        return API_HELPERS.endpoints.backendUrl + url;
    }
    static getRejectAndCloseInquiriesUrl(inquiryId, inquiryInboxId) {
        let url :string = API_HELPERS.endpoints.inquiries.rejectAndCloseOfferByTranslator;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{inquiryId}', inquiryId);
        url = url.replace('{inquiryInboxId}', inquiryInboxId);

        return API_HELPERS.endpoints.backendUrl + url;
    }
    static getCloseOfferUrl(inquiryId, inquiryInboxId) {
        let url :string = API_HELPERS.endpoints.inquiries.closeOfferByClient;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{inquiryId}', inquiryId);
        url = url.replace('{inquiryInboxId}', inquiryInboxId);

        return API_HELPERS.endpoints.backendUrl + url;
    }
    static getAddAttachmentToInquiriesUrl(inquiryId, inquiryInboxId) {
        let url :string = API_HELPERS.endpoints.inquiries.addAttachmentToInquirie;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{inquiryId}', inquiryId);
        url = url.replace('{inquiryInboxId}', inquiryInboxId);

        return API_HELPERS.endpoints.backendUrl + url;
    }
    static getDeleteAttachmentToInquiriesUrl(inquiryId, inquiryInboxId, attachmentId) {
        let url :string = API_HELPERS.endpoints.inquiries.deleteAttachmentToInquirie;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{inquiryId}', inquiryId);
        url = url.replace('{inquiryInboxId}', inquiryInboxId);
        url = url.replace('{attachmentId}', attachmentId);

        return API_HELPERS.endpoints.backendUrl + url;
    }

    static getParamsCurrenciesUrl(translatorId) {
        let url :string = API_HELPERS.endpoints.inquiries.params.getCurrencies;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', translatorId);

        return API_HELPERS.endpoints.backendUrl + url;
    }
    static getParamsCalculatingMethodsUrl(translatorId) {
        let url :string = API_HELPERS.endpoints.inquiries.params.getCalculatingMethod;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', translatorId);

        return API_HELPERS.endpoints.backendUrl + url;
    }
    static getParamsVatRatesUrl(translatorId) {
        let url :string = API_HELPERS.endpoints.inquiries.params.getVatRates;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{translatorId}', translatorId);

        return API_HELPERS.endpoints.backendUrl + url;
    }

    //END INQUIRIES

    //FILES

    //END FILES
    static getFileInfo(fileId) {
        let url :string = API_HELPERS.endpoints.files.getFile;
        url = url.replace('{fileId}', fileId);
        return API_HELPERS.endpoints.backendUrl + url;
    }
    //ACCOUNT

    static getSettingsChangePasswordUrl() :string {
        return API_HELPERS.endpoints.backendUrl + API_HELPERS.endpoints.account.changePassword;
    }

    static getClientSettingsAddressesUrl() :string {
        let url: string = API_HELPERS.endpoints.client.addresses.getAddresses;
        url = url.replace('{culture}', getLangConfig());

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getClientSettingsAddAddressUrl() :string {
        let url: string = API_HELPERS.endpoints.client.addresses.add;
        url = url.replace('{culture}', getLangConfig());

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getClientSettingsUpdateAddressUrl(addressId: string) :string {
        let url: string = API_HELPERS.endpoints.client.addresses.update;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{addressId}', String(addressId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getClientSettingsDeleteAddressUrl(addressId: string) :string {
        let url: string = API_HELPERS.endpoints.client.addresses.delete;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{addressId}', String(addressId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getClientSettingsInvoicesDataUrl() :string {
        let url: string = API_HELPERS.endpoints.client.invoicesData.getInvoicesData;
        url = url.replace('{culture}', getLangConfig());

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getClientSettingsAddInvoiceDataUrl() :string {
        let url: string = API_HELPERS.endpoints.client.invoicesData.add;
        url = url.replace('{culture}', getLangConfig());

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getClientSettingsUpdateInvoiceDataUrl(invoiceDataId: string) :string {
        let url: string = API_HELPERS.endpoints.client.invoicesData.update;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{invoiceDataId}', String(invoiceDataId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getClientSettingsDeleteInvoiceDataUrl(invoiceDataId: string) :string {
        let url: string = API_HELPERS.endpoints.client.invoicesData.delete;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{invoiceDataId}', String(invoiceDataId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getClientSettingsPhonesUrl() :string {
        let url: string = API_HELPERS.endpoints.client.phones.getPhones;
        url = url.replace('{culture}', getLangConfig());

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getClientSettingsPhonePrefixesUrl() :string {
        let url: string = API_HELPERS.endpoints.client.phones.getPrefixes;
        url = url.replace('{culture}', getLangConfig());

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getClientSettingsAddPhoneUrl() :string {
        let url: string = API_HELPERS.endpoints.client.phones.add;
        url = url.replace('{culture}', getLangConfig());

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getClientSettingsUpdatePhoneUrl(phoneId: string) :string {
        let url: string = API_HELPERS.endpoints.client.phones.update;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{phoneId}', String(phoneId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    static getClientSettingsDeletePhoneUrl(phoneId: string) :string {
        let url: string = API_HELPERS.endpoints.client.phones.delete;
        url = url.replace('{culture}', getLangConfig());
        url = url.replace('{phoneId}', String(phoneId));

        return API_HELPERS.endpoints.backendUrl + url ;
    }

    //END ACCOUNT

    //FILES
    static getSupportedFormatFilesUrl() {
        return API_HELPERS.endpoints.backendUrl + API_HELPERS.endpoints.files.getSupportedFormat;
    }
    static getAddFileUrl() {
        return API_HELPERS.endpoints.backendUrl + API_HELPERS.endpoints.files.addFile;
    }
    //END FILES

    static getUserInfoUrl() {
        let url: string = API_HELPERS.endpoints.account.userInfo;
        url = url.replace('{culture}', getLangConfig());
        return API_HELPERS.endpoints.backendUrl + url;
    }

    static getRemoveAccountUrl() {
        let url: string = API_HELPERS.endpoints.account.removeAccount;
        url = url.replace('{culture}', getLangConfig());
        return API_HELPERS.endpoints.backendUrl + url;
    }

    static getResponseCodes() {
        return API_CODES;
    }

    static getResponseMessage(code: string) {
        const { t } = i18n.global
        if (code && API_CODES.includes(code.toString())) {
            return t('errorCodes.' + code);
        } else {
            return t('errorCodes.default');
        }
    }

    static axiosCheckGlobalResponseStatus() {
        axios.interceptors.response.use(function (response) {
            return response
        }, function (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    // if you ever get an unauthorized, logout the user
                    router.push({name: getRouteName('logout')});
                }
            }
            return Promise.reject(error)
        })
    }
}

