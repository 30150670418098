export default {
    pages: {
        forTranslators: "Для Переводчиков",
        account: "Аккаунт",
        contact: "Связаться",
        regulations: "Условия использования",
        enquiries: "Вопросы",
        search: "Искать",
        privacyPolicy: "Политика конфиденциальности",
    },
    header: {
        closeAlt: "Закрыть",
        logout: "Выйти",
        accountIconAlt: "Значок аккаунта",
        searchIconAlt: "Значок поиска",
        searchTranslator: "Искать переводчика",
        selectPageLang: "Выбрать язык сайта",
    },
    footer: {
        accountIconAlt: "Значок аккаунта",
        searchIconAlt: "Значок поиска",
        messageIconAlt: "Значок сообщений",
        enquiriesIconAlt: "Значок переводов",
    },
    searchSection: {
        langLabel: "Язык",
        translationTypeLabel: "Вид перевода",
        locationLabel: "Населенный пункт",
        defaultTranslationType: "Любой",
        search: "Искать",
        resultsCounter: "результатов",
        outOf: "с",
        list: "Список",
        map: "Карта",
        showFilters: "Показать фильтры",
        hideFilters: "Скрыть фильтры",
        interpreting: "Только устный перевод",
    },
    wcag: {
        goToMain: "Перейти на главную",
        resizeFont: "Поменять размер текста. Текущий размер это {current} из {max}",
        googleMap: "Карта, представляющая места, где находятся переводчики",
        interpreting: "Вюбрать только устный перевод"
    },
    homeView: {
        findSwornTranslator: "Найти присяжного переводчика",
        imageOfSwornTranslatorAlt: "Фотография присяжного переводчика",
        howItWorks: "Как это работает",
        itIsEasyText: "Это легко, независимо от того, являетесь ли вы частным лицом или представляете компанию или государственный орган (госучреждение, суд, полицию). Здесь вы найдете всех присяжных переводчиков из базы данных Министерства юстиции.",
        arrowIconAlt: "Значок стрелки",
        searchEngineIconAlt: "Значок поиска",
        searchEngineInfo: "Искать переводчика, предлагающего интересующие вас услуги",
        contactTheTranslatorIconAlt: "Значок звонка",
        contactTheTranslatorInfo: "Свяжитесь с выбранным переводчиком и согласуйте условия услуги",
        sendingDocumentsIconAlt: "Значок документов",
        sendingDocumentsInfo: "Переводчик может запросить документы и потом представить свои условия",
        signedTranslationsIconAlt: "Значок подписанных документов",
        signedTranslationsInfo: "Получите перевод заверенный подписью переводчика",
        frequentlyChosenTranslators: "Самые популярные переводчики",
        showMore: "Показать больше",
        whoIsSwornTranslator: "Кто такой присяжный переводчик?",
        personOfPublicTrust: "Это официальное лицо, которое специализируется на переводе м. ин. процессуальных и официальных документов и удостоверяет копии таких документов на иностранным языке, а также может заверять переводы и копии, выполненные другими лицами.",
        mustCertifyTheComplianceOfTheDocument: "Присяжный переводчик должен удостоверить соответствие переведенного документа оригиналу печатью, содержащей его имя и фамилию, язык, на котором он уполномочен, и свое место в списке присяжных переводчиков.",
        certifiedTranslationsInclude: "Заверенные переводы это чаще всего:",
        proceduralAndOfficialDocuments: "процессуальные и официальные документы",
        workCertificates: "трудовые книжки",
        schoolCertificates: "школьные аттестаты",
        notarialDeeds: "нотариальные действия",
        civilStatusRecords: "действия в ЗАГС",
        lawsAndRegulations: "законы и правила",
        certificates: "свидетельства и сертификаты",
        imageOfMobileAppAlt: "Фото телефона с мобильным приложением",
        downloadMobileApp: "Скачать мобильное приложение",
        mobileAppDescription: "Nascetur amet magna mattis aliquam ac sit. Sit lobortis nibh quam ultrices dictum commodo scelerisque cras. Ultricies tristique adipiscing sagittis est tincidunt pellentesque. Consectetur accumsan, lobortis ullamcorper ac condimentum sodales velit. Parturient at fermentum, orci quisque mauris scelerisque gravida nulla auctor.",
        appStoreAlt: "App store",
        googlePlayAlt: "Google play",
        logo_unia: "Логотип с изображением флага Европейского Союза",
        unia_section: "Логотип Евросоюза, Польши, а также логотип проекта",
    },
    forTranslators: {
        forSwornTranslators: "Для присяжных переводчиков",
        signUpIfYouAreTranslator: "Вы переводчик? Зарегистрируйтесь и активируйте свой VIP-аккаунт, чтобы пользоваться множеством полезных функций",
        swornAppIsDedicatedForTranslators: "SwornApp специально для присяжных переводчиков!",
        benefits: "Льготы",
        free: "Бесплатный",
        standard: "Стандарт",
        vip: "VIP",
        convenientCommunication: "Удобное общение и обмен документами с клиентами",
        abilityToSpecifyPreferredServices: "Возможность указать предпочитаемые услуги",
        editingBusinessCard: "Возможность полного редактирования вашей визитной карточки",
        keepingOfTheRepertory: "Возможность ведения реестра",
        convenientInvoicing: "Удобное выставление счетов",
        onlinePaymentsForCustomers: "Онлайн-платежи для клиентов",
        checkMarkIconAlt: "Значок выбора",
        notAvailableIconAlt: "Значок недоступности",
        month: "месяц",
        freeVipAccount: "Теперь VIP-аккаунт для всех за 0 злотых за первый год, если вы установите мобильное приложение SwornApp.",
        crateVipAccount: "Зарегистрируйте VIP-аккаунт за 0 злотых",
        imageOfSwornTranslatorAlt: "Фотография присяжного переводчика",
        freeAvailable: "доступно с аккаунтом бесплатный",
        freeNotAvailable: "недоступно с аккаунтом бесплатный",
        standardAvailable: "доступно с аккаунтом стандарт",
        standardNotAvailable: "недоступно с аккаунтом стандарт",
        vipAvailable: "доступно с аккаунтом VIP",
        vipNotAvailable: "недоступно с аккаунтом VIP",
        priceFree: "аккаунт бесплатный за",
        priceStandard: "аккаунт стандарт за",
        priceVip: "аккаунт VIP за",
        perMonth: "в месяц",
    },
    findTranslator: {
        starIconAlt: "Значок звездочки",
        findTranslator: "Найти присяжного переводчика",
        loading: "Загрузка...",
        showMore: "Показать больше",
    },
    starsCounter: {
        starIconAlt: "Значок звездочки",
        rating: "Оценка",
        ratingOutOf: "из",
    },
    translatorPage: {
        pointerIconAlt: "Значок указателя",
        mailIconAlt: "Значок конверта",
        phoneIconAlt: "Значок телефона",
        clockIconAlt: "Значок часов",
        translatorImageAlt: "Фотография переводчика из аккаунта",
        translationLanguages: "Языки перевода",
        askForOffer: "Спросите предложение",
        aboutMe: "Обо мне",
        aboutTranslator: "О переводчику",
        aboutTranslatorContent: "Аккаунт переводчика загруженный из базы данных присяжных переводчиков Министерства юстиции.",
        signUpAsTranslatorInfo: "Это ваша визитная карточка? Зарегистрируйтесь и пополните свои данные!",
        signUpAsTranslatorButton: "Создайте аккаунт переводчика",
        experience: "Опыт",
        currently: "В настоящее время",
        education: "Образование",
        deadline: "Срок",
        dateFrom: "с",
        writtenTranslations: "Письменные переводы",
        interpreting: "Устные переводы",
        yes: "Да",
        no: "Нет",
        showMore: "Показать больше",
        showLess: "Показать меньше",
        list: "Список",
        map: "Карта",
        sendRequest: "Отправить запрос",
        sendRequestTo: "кому",
        form: {
            language: "Языки",
            formOfTranslation: 'Вид',
            kindOf: "Тип",
            deliveryMethod: "Способ доставки оригинального документа",
            paymentMethod: "Метод оплаты",
            deadline: "Срок (до дня)",
            receivingMethod: "Способ получения перевода",
            documentFile: "Документ или образец",
            address: 'Адрес',
            fromTime: 'С (часов)',
            toTime: 'До (часов)',
            date: 'Дата',
            addFile: 'Добавить файл',
            chooseFile: 'Выбрано:',
            cancel: "Отмена",
            send: 'Отправить',
            contentMessage: 'Содержание приложения',
            addFilePlaceholder: 'Добавить файл',
            enterMessagePlaceholder: "Введите содержание",
            sendInquiryTo: "Отправить запрос:",
            yourMessage: "Ваше сообщение",
            successfullySendInquiry: "Ваш запрос отправлен успешно",
            errorSendInquiry: "Ошибка отправки запроса",
        },
    },
    translatorCard: {
        pointerIconAlt: "Значок указателя",
        address: "Адрес",
        showOnMap: "Показать на карте",
        seeProfile: "Показать аккаунт",
        deadline: "Срок",
        clockIconAlt: "Значок часов",
        mailIconAlt: "Значок конверта",
        mail: "Адрес электронной почты",
        phone: "Телефон",
        phoneIconAlt: "Значок телефона",
        arrowRightIconAlt: "Значок стрелки вправо",
        votesNumber: "Количество голосов",
        dateFrom: "с",
        deadlineAriaLabel: "Приблизительный срок перевода"
    },
    orders: {
        yes: "Да",
        no: "Нет",
        search: "Искать",
        inProgress: "Заказы в процессе",
        toBePaid: "Заказы к оплате",
        ended: "Завершенные заказы",
        principal: "Заказчик",
        admissionDate: "Дата принятия",
        completionDate: "Срок выполнения",
        translationForm: "Вид перевода",
        valuation: "Расценка",
        admitted: "Принято",
        finish: "Завершить",
        reject: "Отклонить",
        editOrder: "Редактировать заказ",
        status: "Статус",
        repertory: "Реестр",
        markAsPaid: "Отметить как оплаченное",
        payOnline: "Оплатить онлайн",
        endOrder: "Завершить заказ",
        downloadAnInvoice: "Скачать счет",
        statuses: {
            None: "Отсутствие",
            InProgress: "В ходе выполнения",
            Rejected: "Отклонено",
            ToBePaid: "Быть оплаченным",
            Completed: "Завершено"
        },
        orderFrom: "Заказ от",
        order: "Заказ",
        translationPrice: "Цена перевода",
        additionalServices: "Дополнительные услуги",
        gross: "с НДС",
        net: "без НДС",
        pagesNumber: "Количество страниц",
        totalGrossAmount: "Общая сумма с НДС",
        totalPriceToPay: "К оплате",
        endTime: "Дата окончания",
        paymentDeadline: "Дата платежа",
        details: {
            language: "Язык",
            formOfTranslation: "Вид",
            typeOfTranslation: "Тип",
            deadline: "Срок (до дня)",
            originalDocumentDelivery: "Способ доставки оригинального документа",
            receiptOfTranslation: "Способ получения перевода",
            paymentMethod: "Метод оплаты",
            methodOfCalculating: "Способ рассчета стоимости услуги",
            currency: "Валюта",
        },
        markAsPaidModal: {
            title: "Подтверждение оплаты заказа",
            description: "Вы уверены, что хотите подтвердить оплату заказа?",
            markedAsPaid: "Заказ отмечен как оплаченный",
        },
        rejectOrderModal: {
            titleVIP: "Добавить отклоненный заказ в реестр?",
            titleBasic: "Подтверждение отклонения от заказа",
            descriptionBasic: "Вы уверены, что хотите отклонить заказ?",
            orderSuccessfullyRejected: "Заказ был отклонен",
            cancelButton: "Отмена",
            rejectButton: "Отклонить заказ",
            createRepertory: "Да",
            dontCreateRepertory: "Нет",
            createRepertoryAlt: "Добавить в реестр",
            dontCreateRepertoryAlt: "Не добавлять в реестр",
            selectIconAlt: "Значок выбора",
        },
        endOrderModal: {
            saleDate: "Дата продажи",
            issueDate: "Дата документа",
            paymentDate: "Дата оплаты",
            endOrderButton: "Завершить заказ",
            endingOrderButton: "Завершить заказ...",
            issueInvoice: "Выставить счет",
            provideDataForInvoice: "Введите данные заказчика для счета",
            customerName: "Имя и фамилия или название",
            nip: "ИНН",
            street: "Улица",
            buildingNumber: "Номер",
            flatNumber: "Квартира",
            city: "Город",
            postcode: "Почтовый код",
            serviceName: "Название услуги",
            pagesNumber: "Количество страниц",
            rate: "Ставка",
            gross: "с НДС",
            totalAmount: "Итого",
            plnGrossPrice: "Сумма с НДС в польских злотых",
            addAdditionalService: "Добавить дополнительную услугу",
            customerAskedForAnInvoice: "Клиент запросил счет",
            totalPriceToPay: "К оплате:",
            addToRepertory: "Добавить в реестр",
            priceWithVat: "Цены с учетом НДС",
            vatRate: "Ставка НДС"
        }
    },
    invoices: {
        search: "Искать",
        issuedInvoices: "Выставленные счета",
        customer: "Заказчик",
        admissionDate: "Дата принятия",
        endDate: "Дата окончания",
        translationForm: "Вид перевода",
        price: "Сумма",
        status: "Статус",
        downloadAnInvoice: "Скачать счет",
        admitted: "Принято",
    },
    inquiries: {
        today: "Сегодня в",
        to: "Кому",
        search: "Искать",
        ordersFrom: "Заказы от",
        messages: "Сообщения",
        date: "Дата",
        chat: {
            language: "Язык",
            formOfTranslation: "Вид",
            typeOfTranslation: "Тип",
            deadline: "Срок (до дня)",
            originalDocumentDelivery: "Способ доставки оригинального документа",
            receiptOfTranslation: "Способ получения перевода",
            paymentMethod: "Метод оплаты",
            methodOfCalculatingId: "Способ рассчета стоимости услуги",
            currency: "Валюта",
            typeOfRate: "Тип ставки",
            grossRate: "Ставка (PLN с НДС)",
            vatRate: "Ставка НДС",
            expiryDate: "Срок действия предложения",
            orderFrom: "Заказ от",
            methodOfCalculating: "Способ рассчета стоимости услуги",
            priceNet: "Ставка (PLN без НДС)",
            priceGross: "Ставка (PLN с НДС)",
            net: 'без НДС',
            gross: "с НДС",
            successMakeOffer: "Предложение отправлено успешно",
            failMakeOffer: "При создании предложения произошла ошибка",
            successEditOffer: "Предложение отредактировано успешно",
            failEditOffer: "Ошибка при редактировании предложения",
            successAcceptOffer: "Предложение принято успешно",
            failAcceptOffer: "Ошибка принятия предложения",
            address: "Адрес",
            dates: 'Даты',
            date: 'Дата',
            timeTo: 'До (часов)',
            timeFrom: 'С (часов)',
            successCloseInquiries: 'Запрос успешно закрыт',
            failCloseInquiries: 'Ошибка закрытия запроса',
            yes: 'Да',
            no: 'Нет',
            successEditInquiries: "Запрос отредактирован успешно",
            failEditInquiries: "Ошибка при редактировании запроса",
            successRejectOfferAndCloseInquiries: "Предложение отклонено и запрос закрыт успешно",
            failRejectOfferAndCloseInquiries: "Ошибка отклонения предложения.",

            button: {
                cancel: 'Отмена',
                makeOffer: "Сделать предложение",
                sendOffer: "Отправить предложение",
                edit: "Редактировать",
                updates: "Обновить",
                editOffer: "Редактировать",
                acceptOffer: "Принять",
                rejectAndCloseOffer: "Отклонить и закрыть",
                close: 'Закрыть',
                rejectInquirie: 'Отклонить запрос',
                sendMessage: 'Отправить сообщение',
                putMessage: "Введите сообщение",
            },

            confirm: {
                rejectTitle: 'Отклонить и закрыть запрос',
                rejectDescription: 'Вы уверены, что хотите отклонить запрос?',
                closeTitle: 'Закрыть запрос',
                closeDescription: 'Вы уверены, что хотите закрыть запрос?',
                rejectAndAddToRepertory: 'Добавить отклоненный запрос в реестр?',

            },

            validation: {
                language: 'Указанный язык не существует',
                formOfTranslation: 'Указанный вид перевода не существует',
                typeOfTranslation: 'Указанный тип перевода не существует',
                failSendFile: 'Ошибка загрузки файла',
                checkExtensions: "Проверить расширение файла",
            }
        },

    },
    notFoundPage: {
        error404: "Ошибка 404",
        pageNotExist: "Нет такой страницы",
        backToHomePage: "Вернуться на главную страницу",
        pageNotFoundIconAlt: "Значок страницы 404",
    },
    modal: {
        close: "Закрыть"
    },
    autocomplete: {
        loading: "Загрузка...",
        noResult: "Нет результатов"
    },
    loginAndRegistrationView: {
        title: "Войти или зарегистрироваться",
        login: "Войти",
        registration: "Зарегистрироваться",
        eyeIconAlt: "Значок глаза",
        showPassword: "Показать пароль",
        hidePassword: "Скрыть пароль",
    },
    login: {
        email: "Электронный адрес",
        password: "Пароль",
        loginButton: "Войти",
        forgotPassword: "Вы не помните пароль?"
    },
    registration: {
        accountActivatedAlt: "Значок подтверждения",
        customerOrCompany: "Индивидуальный клиент или компания",
        publicInstitution: "Государственное учреждение",
        swornTranslator: "Присяжный переводчик",
        selectIconAlt: "Значок выбора",
        email: "Электронный адрес",
        businessEmail: "Деловая электронная почта",
        businessPhone: "Рабочий телефон",
        institutionName: "Учреждение (точное название)",
        institutionWebsite: "Адрес Веб-сайта учреждения",
        position: "Должность",
        name: "Имя",
        surname: "Фамилия",
        password: "Пароль",
        translatorNumber: "Введите свой номер переводчика (TP/1234/00)",
        regulations_acceptance_start: "Согласен (-а) с",
        regulations_acceptance_link: "условиями использования",
        regulations_acceptance_end: "и обработку моих персональных данных...",
        signUpButton: "Зарегистрироваться",
        next: "Далее",
        confirmPhone: "Подтвердить номер телефона",
        confirmationByLetter: "Спасибо! Ждите письмо от нас",
        confirmEmail: "Подтвердить адрес электронной почты",
        confirmEmailInfo: "Мы отправили вам письмо. Нажмите на ссылку в полученном письме чтобы завершить регистрацию.",
        confirmTranslatorEmailInfo: "Мы отправили Вам письмо на адрес электронной почты, указан в базе данных присяжных переводчиков Министерства юстиции:",
        clickLinkToRegister: "Нажмите на ссылку в письме чтобы завершить регистрацию.",
        confirmTranslatorPhoneInfo: "Мы отправили Вам СМС с проверочным кодом на номер телефона, указан в базе данных присяжных переводчиков Министерства юстиции:",
        confirmTranslatorLetterInfo: "В течение нескольких дней мы отправим заказное письмо с проверочным кодом на ваш почтовый адрес, указан в базе данных присяжных переводчиков Министерства юстиции.",
        linkSentIconAlt: "Значок отправки сообщения",
        accountActivated: "Аккаунт активирован",
        goToYourAccount: "Перейти в свой аккаунт",
        verificationError: "Ошибка подтверждения",
        sendNewLink: "Отправить новую ссылку",
        sending: "Отправка...",
        backToHomePage: "Вернуться на главную страницу",
        goFurther: "Перейти далее",
        enterEmail: "Введите адрес электронной почты",
        translatorVerificationButton: "У Вас есть проверочный код?",
        translatorVerification: {
            verifyAndFillData: "Провердить и заполнить данные",
            enterTpNumber: "Ввести код переводчика",
            enterVerificationCode: "Ввести проверочный код",
            verificationCode: "Проверочный код",
            enterEmail: "Введите адрес электронной почты",
        },
    },
    forgotPassword: {
        title: "Введите адрес электронной почты, чтобы изменить пароль",
        email: "Электронный адрес",
        enterEmail: "Введите адрес электронной почты",
        backToLogin: "Назад ко входу",
        next: "Далее",
        linkSentTitle: "Проверьте электронную почту, чтобы изменить пароль",
        linkSentIconAlt: "Значок отправки сообщения",
        linkSentInfo: "Мы отправили вам письмо. Нажмите на ссылку, чтобы изменить пароль.",
    },
    changePassword: {
        title: "Введите новый пароль",
        email: "Электронный адрес",
        enterEmail: "Введите адрес электронной почты",
        password: "Пароль",
        changePasswordButton: "Изменить пароль",
        passwordChanged: "Ваш пароль изменен"
    },
    translatorProfile: {
        absence: {
            absenceAtWork: "Перерыв в работе",
            setAbsenceInWork: 'Установить перерыв в работе',
            dateFrom: "с",
            dateTo: "по",
            indefinitely: "На неопределенный срок",
            messageForClients: "Сообщение для клиентов",
            save: "Сохранить",
            successDisableAbsence: "Перерыв отключен успешно",
            successSetAbsence: "Перерыв установлен успешно",
            valid: {
                dateGreaterThan: "Дата не может быть меньше, чем дата отпуска с"
            }
        }

    },
    tabs: {
        hello: "Добро пожаловать",
        requests: "Запросы",
        orders: "Заказы",
        invoice: "Счета",
        repertory: "Реестр",
        profile: "Аккаунты",
        settings: "Настройки"
    },
    validation: {
        email: "Указанный адрес электронной почты неверен",
        required: "Поле обязательно для заполнения",
        password: "Пароль должен содержать: мин. 8 символов, мин. 1 прописная буква, мин. 1 строчная буква, мин. 1 специальный символ",
        maxFileSize: "Файл под названием {fileName} слишком большой",
        maximumFiles: "Максимальное количество файлов: {maxFiles}",
        maximumSize: "Общий максимальный размер файлов: {maximumSize}",
        passwordConfirmation: "Пароли должны быть одинаковы",
        max_nip: "ИНН должен состоять из 10 цифр",
    },
    errorCodes: {
        2: "Неверный токен",
        3: "Неверный токен",
        4: "Неверный токен",
        5: "Аккаунт заблокирован",
        6: "Неверный логин или пароль",
        7: "Адрес эл. почты не подтвержден",
        8: "Двухфакторный код отправлен",
        9: "Пользователь уже существует",
        10: "Ошибка. Пожалуйста, попробуйте позже.",
        14: "Адрес эл. почты уже подтвержден",
        15: "Пользователь был удален",
        default: "При обработке вашего запроса произошла ошибка."
    },
    languageSettings: {
        interpreting: "устные переводы",
        deadline: "Срок",
        choosePreferredServices: "Выберите интересующие вас услуги",
        dateFrom: "с"
    },
    profileSettings: {
        aboutMe: "Обо мне",
        save: "Сохранить",
        saving: "Идет сохранение...",
        edit: "Редактировать",
        cancel: "Отмена",
        change: "Изменить",
        delete: "Удалить",
        deleting: "Идет удаление...",
        add: "Добавить",
        yourPhoto: "Ваша фотография",
        profilePage: "Фотография аккаунта",
        addFilePlaceholder: "Выбрать файл",
        choseFile: "Выбран",
        experience: {
            title: "Опыт",
            companyName: "Компания",
            location: "Место нахождения",
            position: "Должность",
            workStart: "Начало работы",
            workEnd: "Завершение работы",
            workingHereCurrently: "В настоящее время работаю здесь",
            currently: "В настоящее время"
        },
        education: {
            title: "Образование",
            school: "Школа",
            specialization: "Специализация",
            academicDegree: "Ученая степень",
            workStart: "Начало",
            workEnd: "Завершение (или планированное завершение)",
        },
    },
    settings: {
        save: "Сохранить",
        saving: "Идет сохранение...",
        edit: "Редактировать",
        cancel: "Отмена",
        change: "Изменить",
        delete: "Удалить",
        deleting: "Идет удаление...",
        add: "Добавить",
        arrowIconAlt: "Значок стрелки",
        addresses: {
            title: "Адреса",
            lockedAddress: "Адрес заблокирован",
            lockIconAlt: "Значок замка",
            defaultAddress: "Адрес по умолчанию",
            setAsDefaultAddress: "Установить по умолчанию",
            settingAsDefaultAddress: "Установление по умолчанию...",
            street: "Улица",
            buildingNumber: "Номер",
            flatNumber: "Квартира",
            city: "Город",
            postcode: "Почтовый код",
        },
        emails: {
            title: "Адреса электронной почты",
            lockedEmail: "Адрес электронной почты заблокирован",
            lockIconAlt: "Значок замка",
            defaultEmail: "Адрес электронной почты по умолчанию",
            setAsDefaultEmail: "Установить по умолчанию",
            settingAsDefaultEmail: "Установление по умолчанию...",
            email: "Эл. почта",
        },
        clientEmail: {
            title: "Адрес электронной почты",
            email: "Эл. почта",
        },
        phones: {
            title: "Телефоны",
            lockedPhone: "Телефон заблокирован",
            lockIconAlt: "Значок замка",
            defaultPhone: "Телефон по умолчанию",
            setAsDefaultPhone: "Установить по умолчанию",
            settingAsDefaultPhone: "Установление по умолчанию...",
            phone: "Телефон",
            phonePrefix: "Префикс",
            isLandline: "Телефон домашний",
        },
        invoicesData: {
            title: "Ваши данные для счета",
            street: "Улица",
            buildingNumber: "Номер",
            flatNumber: "Квартира",
            city: "Город",
            postcode: "Почтовый код",
            name: "Название",
            nip: "ИНН",
            postOffice: "Почта",
            iAmVatPayer: "Являюсь плательщиком НДС",
            basicNumeration: "Базовая нумерация",
            courtsProsecutorsPoliceNumeration: "Нумерация Суды, прокуратура и полиция",
            prefix: "Префикс",
            numerationType: "Тип нумерации",
            format: "Формат",
            startFrom: "Начать с",
            registeredInBdo: "Компания зарегистрирована в BDO",
            bdoNumber: "Номер BDO",
            bankAccountsNumbers: "Номера банковских счетов",
            currency: "Валюта",
            country: "Страна",
            bankAccountNumber: "Номер",
            kindNumberingTypes: {
                1: "Годовой",
                2: "Месячный",
            },
            defaultInvoiceData: "Данные по умолчанию",
            setAsDefaultInvoiceData: "Установить по умолчанию",
            settingAsDefaultInvoiceData: "Установление по умолчанию...",
        },
        removeAccount: {
            title: "Удаление аккаунта",
            confirmation: "Подтверждаю, что хочу удалить аккаунт",
            removed: "Аккаунт удален",
            cancelButton: "Отмена",
            confirmButton: "Подтвердить",
            confirmModalTitle: "Подтвердить удаление аккаунта",
            confirmModalDescription: "Удаленный аккаунт нельзя повторно активировать.<br/> После подтверждения будете автоматически выведены из приложения.",
        },
        subscription: {
            title: "Ваша подписка",
            showYourSubscription: "Посмотреть подробности подписки",
            to: "до",
            validFrom: "Действует с",
            validTo: "Действует до",
            subscriptionId: "ID подписки",
        },
        changePassword: {
            title: "Изменить пароль",
            currentPassword: "Действующий пароль",
            newPassword: "Новый пароль",
            repeatPassword: "Повторить пароль",
            passwordChanged: "Пароль изменен",
        },
    },
    months: {
        1: "Январь",
        2: "Февраль",
        3: "Март",
        4: "Апрель",
        5: "Май",
        6: "Июнь",
        7: "Июль",
        8: "Август",
        9: "Сентябрь",
        10: "Октябрь",
        11: "Ноябрь",
        12: "Декабрь",
    },
    repertory: {
        itemsNeedCompleted: "Графы, которые нужно заполнить",
        completedItems: "Заполнено",
        adoptionDate: "Дата принятия",
        refusalDate: "Дата отказа",
        ordinalNumber: "№ (1)",
        orderingDesignation: "Обозначение заказчика",
        adoptionOrderDate: "Дата принятия заказа (2)",
        orderingDesignationOrPurchaser: "Обозначение заказчика или покупателя данного перевода (3)",
        translationForm: "Вид перевода",
        descriptionTranslatedDocument: "Описание переведенного документа",
        nameDateAndDesignation: "Наименование, дата и обозначение документа  (4)",
        languageDocumentDrawek: "Язык, на котором будет составлен документ (5)",
        commentsOnType: "Комментарии к типу, форме и состоянию документа (7)",
        activityDesignation: "Обозначение действия",
        typeOfActivityPerformed: "Тип выполненной деятельности и язык перевода (8)",
        numberOfPagesOfTranslation: "Количество страниц перевода (9)",
        numberOfCopiesMade: "Количество изготовленных копий (10)",
        refusalToProvideTranslation: "Отказ от выполнения перевода для лиц, указанных в ст. 15 Закона о профессии присяжного переводчика",
        authorityRequestingTranslation: "Учреждение, запрашивающие перевод (17)",
        reasonForRefusalTranslation: "Причина отказа от перевода (18)",
        remarks: "Заметки",
        interpretationDescription: "Описание устного перевода",
        date: "Дата",
        place: "Место (11)",
        duration: "Продолжительность",
        amountCollectedRemuneration: "Сумма полученного вознаграждения (14)",
        dateOfReturnDocument: "Дата возврата документа вместе с переводом ( 15)",
        range: "Спектр",
        edit: "Редактировать",
        export: "Экспорт",
        search: "Искать",
    },
    metadata: {
        searchTranslator: {
            title: "Tłumacze przysięgli",
            description: "Tłumacze przysięgli",
        },
        home: {
            title: 'Znajdź tłumacza',
            description: 'Znajdź mi tłumacza przysięgłego',
        },
        findTranslator: {
            title: 'Znajdź tłumacza',
            description: 'Znajdź mi tłumacza przysięgłego',
        },
        forTranslators: {
            title: 'Dla tłumaczy przysięgłych',
            description: 'Jesteś tłumaczem? Zarejestruj się i aktywuj konto VIP, aby korzystać z wielu przydatnych funkcji',
        },
        // translatorPage: {
        //     title: 'Dla tłumaczy przysięgłych',
        //     description: 'Jesteś tłumaczem? Zarejestruj się i aktywuj konto VIP, aby korzystać z wielu przydatnych funkcji',
        // }
        login: {
            title: 'Logowanie',
            description: 'SwornApp - logowanie'
        },
        registration: {
            title: 'Rejestracja',
            description: 'SwornApp - rejestracja'
        },
        registrationEmailVerification: {
            title: 'Weryfikacja email',
            description: ''
        },
        registrationTranslatorVerification: {
            title: 'Weryfikacja tłumacza',
            description: ''
        },
        forgotPassword: {
            title: 'Zapomniałeś hasła?',
            description: 'Zapomniałeś hasła?'
        },
        changePassword: {
            title: 'Zmiana hasła',
            description: 'Zmień swoje hasło'
        },
        inquiries: {
            title: 'Zapytania',
            description: ''
        },
        orders: {
            title: 'Zamówienia',
            description: ''
        },
        invoices: {
            title: 'Faktury',
            description: ''
        },
        profileSettings: {
            title: 'Ustawienia profilu',
            description: ''
        },
        accountSettings: {
            title: 'Ustawienia konta',
            description: ''
        },
    }
}