export default {
    pages: {
        forTranslators: "Für Übersetzer und Dolmetscher",
        account: "Konto",
        contact: "Kontakt",
        regulations: "Ordnung",
        enquiries: "Anfragen",
        search: "Suche",
        privacyPolicy: "Datenschutzrichtlinie",
    },
    header: {
        closeAlt: "Schließen",
        logout: "Ausloggen",
        accountIconAlt: "Konto-Symbol",
        searchIconAlt: "Lupen-Symbol",
        searchTranslator: "Übersetzer suchen",
        selectPageLang: "Sprache der Seite auswählen",
    },
    footer: {
        accountIconAlt: "Konto-Symbol",
        searchIconAlt: "Lupen-Symbol",
        messageIconAlt: "Nachrichten-Symbol",
        enquiriesIconAlt: "Übersetzungs-Symbol",
    },
    searchSection: {
        langLabel: "Sprache",
        translationTypeLabel: "Übersetzungstyp",
        locationLabel: "Ort",
        defaultTranslationType: "Beliebig",
        search: "Suche",
        resultsCounter: "Ergebnisse",
        outOf: "aus",
        list: "Liste",
        map: "Karte",
        showFilters: "Filter anzeigen",
        hideFilters: "Filter ausblenden",
        interpreting: "Nur Dolmetschen",
    },
    wcag: {
        goToMain: "Zum Hauptinhalt gehen",
        resizeFont: "Textgröße. Die aktuelle Größe ist {current} von {max}",
        googleMap: "Karte mit Punkten, an denen sich Übersetzer und Dolmetscher befinden",
        interpreting: "Wählen Sie nur Dolmetschen"
    },
    homeView: {
        findSwornTranslator: "Einen staatlich geprüften Übersetzer und Dolmetscher finden'",
        imageOfSwornTranslatorAlt: "Foto des staatlich geprüften Übersetzers",
        howItWorks: "Wie es funktioniert",
        itIsEasyText: "Es ist einfach, unabhängig davon, ob Sie eine Privatperson sind oder ein Unternehmen oder eine öffentliche Einrichtung (Behörde, Gericht, Polizei) vertreten. Sie können alle staatlich geprüften Übersetzer und Dolmetscher in der Datenbank des Justizministeriums bei uns finden",
        arrowIconAlt: "Pfeil-Symbol",
        searchEngineIconAlt: "Suchmaschinen-Symbol",
        searchEngineInfo: "Suchen Sie nach einem Übersetzer und Dolmetscher, der die von Ihnen gewünschte Dienstleistung anbietet",
        contactTheTranslatorIconAlt: "Anruf-Symbol",
        contactTheTranslatorInfo: "Kontaktieren Sie den ausgewählten Übersetzer und stimmen Sie den Nutzungsbedingungen zu",
        sendingDocumentsIconAlt: "Dokumenten-Symbol",
        sendingDocumentsInfo: "Der Übersetzer oder Dolmetscher kann um die Übermittlung von Dokumenten bitten und wird ein Angebot unterbreiten",
        signedTranslationsIconAlt: "Symbol der signierten Dokumente",
        signedTranslationsInfo: "Sie erhalten eine mit der Unterschrift des Übersetzers beglaubigte Übersetzung",
        frequentlyChosenTranslators: "Die beliebtesten Übersetzer",
        showMore: "mehr anzeigen",
        whoIsSwornTranslator: "Wer ist ein staatlich geprüfter Übersetzer",
        personOfPublicTrust: "Es handelt sich um eine Person des öffentlichen Vertrauens, die auf die Übersetzung u. a. von Verfahrens- und amtlichen Dokumenten und die Beglaubigung von fremdsprachigen Kopien solcher Dokumente spezialisiert ist und die von anderen angefertigten Übersetzungen und Kopien beglaubigen kann.",
        mustCertifyTheComplianceOfTheDocument: "Der vereidigte Übersetzer muss die Übereinstimmung des übersetzten Dokuments mit dem Original durch ein Siegel bestätigen, das seinen Namen, die Sprache, für die er qualifiziert ist, und seine Position in der Liste der staatlich geprüften Übersetzer enthält.",
        certifiedTranslationsInclude: "Beglaubigte Übersetzungen umfassen in der Regel",
        proceduralAndOfficialDocuments: "Verfahrens- und amtliche Dokumente",
        workCertificates: "Arbeitszeugnisse",
        schoolCertificates: "Schulzeugnisse",
        notarialDeeds: "Notariatsurkunden",
        civilStatusRecords: "Personenstandsurkunden",
        lawsAndRegulations: "Gesetze und Vorschriften",
        certificates: "Bescheinigungen und Zertifikate",
        imageOfMobileAppAlt: "Foto eines Telefons mit Handy-App",
        downloadMobileApp: "Mobile App herunterladen",
        mobileAppDescription: "Nascetur amet magna mattis aliquam ac sit. Sit lobortis nibh quam ultrices dictum commodo scelerisque cras. Ultricies tristique adipiscing sagittis est tincidunt pellentesque. Consectetur accumsan, lobortis ullamcorper ac condimentum sodales velit. Parturient at fermentum, orci quisque mauris scelerisque gravida nulla auctor.",
        appStoreAlt: "App store",
        googlePlayAlt: "Google play",
        logo_unia: "Logo, das die Flagge der Europäischen Union darstellt",
        unia_section: "Europäische Union, polnische Logos und Projektlogos",
    },
    forTranslators: {
        forSwornTranslators: "Für staatlich geprüfte Übersetzer und Dolmetscher",
        signUpIfYouAreTranslator: "Sind Sie ein Übersetzer oder Dolmetscher? Melden Sie sich an und aktivieren Sie Ihr VIP-Konto, um von vielen nützlichen Funktionen zu profitieren.",
        swornAppIsDedicatedForTranslators: "SwornApps speziell für vereidigte Übersetzer und Dolmetscher!",
        benefits: "Vorteile",
        free: "Free",
        standard: "Standard",
        vip: "VIP",
        convenientCommunication: "Bequeme Kommunikation und Dokumentenaustausch mit Kunden",
        abilityToSpecifyPreferredServices: "Möglichkeit, bevorzugte Dienstleistungen anzugeben",
        editingBusinessCard: "Möglichkeit, Ihre Visitenkarte vollständig zu bearbeiten",
        keepingOfTheRepertory: "Möglichkeit des Führens der Urkundenrolle",
        convenientInvoicing: "Bequeme Rechnungsstellung",
        onlinePaymentsForCustomers: "Online-Zahlungen für Kunden",
        checkMarkIconAlt: "Markierungs-Symbol",
        notAvailableIconAlt: "Nicht verfügbar-Symbol",
        month: "Monat",
        freeVipAccount: "Jetzt ein VIP-Konto für 0 PLN für das erste Jahr, wenn Sie die SwornApp mobile App installieren.",
        crateVipAccount: "Erstellen Sie ein VIP-Konto für 0 PLN",
        imageOfSwornTranslatorAlt: "Foto des staatlich geprüften Übersetzers und Dolmetschers",
        freeAvailable: "verfügbar mit einem Free-Konto",
        freeNotAvailable: "nicht verfügbar mit Free-Konto ",
        standardAvailable: "verfügbar mit Standardkonto",
        standardNotAvailable: "nicht verfügbar mit Standardkonto",
        vipAvailable: "verfügbar mit VIP-Konto",
        vipNotAvailable: "nicht verfügbar mit VIP-Konto",
        priceFree: "Konto frei für",
        priceStandard: "Standardkonto für",
        priceVip: "VIP-Konto für",
        perMonth: "pro Monat",
    },
    findTranslator: {
        starIconAlt: "Stern-Symbol",
        findTranslator: "Einen staatlich geprüften Übersetzer oder Dolmetscher finden",
        loading: "Laden...",
        showMore: "Mehr anzeigen",
    },
    starsCounter: {
        starIconAlt: "Stern-Symbol",
        rating: "Bewertung",
        ratingOutOf: "von",
    },
    translatorPage: {
        pointerIconAlt: "Markierungssymbol",
        mailIconAlt: "Umschlag-Symbol",
        phoneIconAlt: "Telefon-Symbol",
        clockIconAlt: "Uhr-Symbol",
        translatorImageAlt: "Profilbild des Übersetzers oder Dolmetschers",
        translationLanguages: "Sprachen der Übersetzung",
        askForOffer: "Anfragen",
        aboutMe: "Über mich",
        aboutTranslator: "Über den Übersetzer/Dolmetscher",
        aboutTranslatorContent: "Übersetzerkonto aus der Datenbank des Justizministeriums für staatliche geprüfte Übersetzer",
        signUpAsTranslatorInfo: "Ist das Ihre Visitenkarte? Melden Sie sich an und ergänzen Sie Ihre Daten!",
        signUpAsTranslatorButton: "Registrieren Sie sich als Übersetzer oder Dolmetscher",
        experience: "Erfahrung",
        currently: "derzeit",
        education: "Ausbildung",
        deadline: "Frist",
        dateFrom: "von",
        writtenTranslations: "Übersetzen",
        interpreting: "Dolmetschen",
        yes: "Ja",
        no: "Nein",
        showMore: "mehr anzeigen",
        showLess: "weniger anzeigen",
        list: "Liste",
        map: "Karte",
        sendRequest: "Eine Anfrage senden",
        sendRequestTo: "an",
        form: {
            language: "Sprachen",
            formOfTranslation: 'Form',
            kindOf: "Art",
            deliveryMethod: "Art der Vorlage des Originals",
            paymentMethod: "Zahlungsmethode",
            deadline: "Frist (bis zum)",
            receivingMethod: "Methode der Übersetzungsabholung",
            documentFile: "Dokument oder Probetext",
            address: 'Adresse',
            fromTime: 'von .............. Uhr',
            toTime: 'bis .............. Uhr',
            date: 'Datum',
            addFile: 'Datei hinzufügen',
            chooseFile: 'Ausgewählt',
            cancel: "Abbrechen",
            send: 'Senden',
            contentMessage: 'Inhalt der Anwendung',
            addFilePlaceholder: 'Datei hinzufügen',
            enterMessagePlaceholder: "Inhalt eingeben",
            sendInquiryTo: "Anfrage senden an:",
            yourMessage: "Ihre Nachricht",
            successfullySendInquiry: "Anfrage erfolgreich gesendet",
            errorSendInquiry: "Fehler beim Senden der Anfrage",
        },
    },
    translatorCard: {
        pointerIconAlt: "Markierungssymbol",
        address: "Adresse",
        showOnMap: "Auf der Karte anzeigen",
        seeProfile: "Siehe Profil",
        deadline: "Frist",
        clockIconAlt: "Uhr-Symbol",
        mailIconAlt: "Umschlag-Symbol",
        mail: "E-Mail Adresse",
        phone: "Telefon",
        phoneIconAlt: "Telefon-Symbol",
        arrowRightIconAlt: "Rechtspfeil-Symbol",
        votesNumber: "Anzahl der Stimmen",
        dateFrom: "von",
        deadlineAriaLabel: "Übersetzungsfrist ungefähr"
    },
    orders: {
        yes: "Ja",
        no: "Nein",
        search: "Suche",
        inProgress: "Aufträge in Bearbeitung",
        toBePaid: "Aufträge zu bezahlen",
        ended: "Erledigte Aufträge",
        principal: "Auftraggeber",
        admissionDate: "Annahmedatum",
        completionDate: "Fertigstellungsfrist",
        translationForm: "Übersetzungsform",
        valuation: "Kostenvoranschlag",
        admitted: "Angenommen",
        finish: "Beenden",
        reject: "Ablehnen",
        editOrder: "Auftrag bearbeiten",
        status: "Status",
        repertory: "Urkundenrolle",
        markAsPaid: "Als bezahlt markieren",
        payOnline: "Online bezahlen",
        endOrder: "Auftrag abschließen",
        downloadAnInvoice: "Download der Rechnung",
        statuses: {
            None: "Keine",
            InProgress: "In Bearbeitung",
            Rejected: "Abgelehnt",
            ToBePaid: "Bezahlen",
            Completed: "Erledigt"
        },
        orderFrom: "Auftrag von",
        order: "Auftrag",
        translationPrice: "Kosten der Übersetzung",
        additionalServices: "Zusätzliche Dienstleistungen",
        gross: "Brutto",
        net: "Netto",
        pagesNumber: "Anzahl der Seiten",
        totalGrossAmount: "Gesamtbruttobetrag",
        totalPriceToPay: "Insgesamt fällig",
        endTime: "Abschlussfrist",
        paymentDeadline: "Zahlungsdatum",
        details: {
            language: "Sprache",
            formOfTranslation: "Form",
            typeOfTranslation: "Typ",
            deadline: "Frist bis",
            originalDocumentDelivery: "Art der Vorlage des Originals",
            receiptOfTranslation: "'Methode zum Empfang der Übersetzung",
            paymentMethod: "Zahlungsmethode",
            methodOfCalculating: "Methode zur Berechnung der Kosten der Dienstleistung",
            currency: "Währung",
        },
        markAsPaidModal: {
            title: "Bestätigung der Zahlung des Auftrags",
            description: "Sind Sie sicher, dass Sie die Zahlung des Auftrags bestätigen wollen?",
            markedAsPaid: "Der Auftrag wurde als bezahlt markiert",
        },
        rejectOrderModal: {
            titleVIP: "Den abgelehnten Auftrag in die Urkundenrolle aufnehmen?",
            titleBasic: "Bestätigung der Ablehnung des Auftrags",
            descriptionBasic: "Sind Sie sicher, dass Sie den Auftrag ablehnen wollen?",
            orderSuccessfullyRejected: "Der Auftrag wurde abgelehnt",
            cancelButton: "Abbrechen",
            rejectButton: "Auftrag ablehnen",
            createRepertory: "Ja",
            dontCreateRepertory: "Nein",
            createRepertoryAlt: "In die Urkundenrolle aufnehmen",
            dontCreateRepertoryAlt: "Nicht in die Urkundenrolle aufnehmen",
            selectIconAlt: "Auswahl-Symbol",
        },
        endOrderModal: {
            saleDate: "Verkaufsdatum",
            issueDate: "Ausstellungsdatum",
            paymentDate: "Zahlungsdatum",
            endOrderButton: "Auftrag beenden",
            endingOrderButton: "Auftrag beenden...",
            issueInvoice: "Rechnung ausstellen",
            provideDataForInvoice: "Geben Sie die Daten des Auftraggebers für die Rechnung an",
            customerName: "Vorname und Nachname",
            nip: "UStIdNr.",
            street: "Straße",
            buildingNumber: "Hausnummer",
            flatNumber: "Raum",
            city: "Ort",
            postcode: "PLZ",
            serviceName: "Dienstleistungsbezeichung",
            pagesNumber: "Anzahl der Seiten",
            rate: "Satz",
            gross: "Brutto",
            totalAmount: "Gesamtbetrag",
            plnGrossPrice: "Bruttobetrag in PLN",
            addAdditionalService: "Zusätzliche Dienstleistungen hinzufügen",
            customerAskedForAnInvoice: "Der Kunde hat eine Rechnung angefordert",
            totalPriceToPay: "Insgesamt fällig",
            addToRepertory: "In die Urkundenrolle aufnehmen",
            priceWithVat: "Preise inklusive Mehrwertsteuer",
            vatRate: "Mehrwertsteuersatz"
        }
    },
    invoices: {
        search: "Suche",
        issuedInvoices: "Ausgestellte Rechnungen",
        customer: "Auftraggeber",
        admissionDate: "Annahmedatum",
        endDate: "Abschlussdatum",
        translationForm: "Übersetzungsform",
        price: "Betrag",
        status: "Status",
        downloadAnInvoice: "Download der Rechnung",
        admitted: "Angenommen",
    },
    inquiries: {
        today: "Heute um",
        to: "Bis",
        search: "Suche",
        ordersFrom: "Aufträge von",
        messages: "Nachrichten",
        date: "Datum",
        chat: {
            language: "Sprache",
            formOfTranslation: "Form",
            typeOfTranslation: "Typ",
            deadline: "Frist bis zum",
            originalDocumentDelivery: "Art der Vorlage des Originals",
            receiptOfTranslation: "Methode zum Empfang der Übersetzung",
            paymentMethod: "Zahlungsmethode",
            methodOfCalculatingId: "Methode zur Berechnung der Kosten der Dienstleistung",
            currency: "Währung",
            typeOfRate: "Satzart",
            grossRate: "Satz (PLN Brutto)",
            vatRate: "Mehrwertsteuersatz",
            expiryDate: "Ablaufdatum des Angebots",
            orderFrom: "Bestellung von",
            methodOfCalculating: "Methode zur Berechnung der Kosten der Dienstleistung",
            priceNet: "Satz (PLN Netto)",
            priceGross: "Satz (PLN Brutto)",
            net: 'Netto',
            gross: "Brutto",
            successMakeOffer: "Angebot erfolgreich gesendet",
            failMakeOffer: "Bei der Erstellung des Angebots ist ein Fehler aufgetreten",
            successEditOffer: "Erfolgreich bearbeitetes Angebot",
            failEditOffer: "Fehler beim Bearbeiten des Angebots",
            successAcceptOffer: "Erfolgreich angenommenes Angebot",
            failAcceptOffer: "Fehler bei der Angebotsannahme",
            address: "Adresse",
            dates: 'Daten',
            date: 'Datum',
            timeTo: 'Bis ..... Uhr',
            timeFrom: 'von ...... Uhr',
            successCloseInquiries: 'Anfrage erfolgreich geschlossen',
            failCloseInquiries: 'Fehler beim Schließen der Anfrage',
            yes: 'Ja',
            no: 'Nein',
            successEditInquiries: "Anfrage erfolgreich bearbeitet",
            failEditInquiries: "Fehler beim Bearbeiten von Anfragen",
            successRejectOfferAndCloseInquiries: "Angebot erfolgreich abgelehnt und Anfrage geschlossen",
            failRejectOfferAndCloseInquiries: "Fehler beim Ablehnen eines Angebots.",

            button: {
                cancel: 'Abbrechen',
                makeOffer: "Angebot unterbreiten",
                sendOffer: "Angebot senden",
                edit: "Bearbeiten",
                updates: "Aktualisieren",
                editOffer: "Bearbeiten",
                acceptOffer: "Akzeptieren",
                rejectAndCloseOffer: "Ablehnen und Schließen",
                close: 'Beenden',
                rejectInquirie: 'Anfrage ablehnen',
                sendMessage: 'Nachricht senden',
                putMessage: "Nachricht eingeben",
            },

            confirm: {
                rejectTitle: 'Anfrage ablehnen und beenden',
                rejectDescription: 'Sind Sie sicher, dass Sie die Anfrage ablehnen wollen?',
                closeTitle: 'Anfrage beenden',
                closeDescription: 'Sind Sie sicher, dass Sie die Anfrage beenden wollen?',
                rejectAndAddToRepertory: 'Die abgelehnte Anfrage in die Urkundenrolle aufnehmen?',

            },

            validation: {
                language: 'Die angegebene Sprache existiert nicht',
                formOfTranslation: 'Die angegebene Form existiert nicht',
                typeOfTranslation: 'Art der Übersetzung vorhanden',
                failSendFile: 'Fehler bei der Dateiübertragung',
                checkExtensions: "Dateierweiterungen prüfen",
            }
        },

    },
    notFoundPage: {
        error404: "Fehler 404",
        pageNotExist: "Diese Seite existiert nicht",
        backToHomePage: "Zurück zur Startseite",
        pageNotFoundIconAlt: "404-Seiten-Symbol",
    },
    modal: {
        close: "Schließen"
    },
    autocomplete: {
        loading: "Laden...",
        noResult: "Keine Ergebnisse"
    },
    loginAndRegistrationView: {
        title: "Einloggen oder ein Konto erstellen",
        login: "Einloggen",
        registration: "Registrierung",
        eyeIconAlt: "Augen-Ikone",
        showPassword: "Passwort anzeigen",
        hidePassword: "Passwort verbergen",
    },
    login: {
        email: "E-Mail",
        password: "Passwort",
        loginButton: "Einloggen",
        forgotPassword: "Sie haben Ihr Passwort vergessen"
    },
    registration: {
        accountActivatedAlt: "Bestätigungssymbol",
        customerOrCompany: "Einzelner Kunde oder Unternehmen",
        publicInstitution: "Öffentliche Einrichtung",
        swornTranslator: "staatlich geprüfter Übersetzer und Dolmetscher",
        selectIconAlt: "Auswahl-Symbol",
        email: "E-Mail",
        businessEmail: "Dienst-E-Mail",
        businessPhone: "Dienst-Telefon",
        institutionName: "Einrichtung (genauer Name)",
        institutionWebsite: "Website-Adresse der Einrichtung",
        position: "Stelle",
        name: "Vorname",
        surname: "Nachname",
        password: "Passwort",
        translatorNumber: "Geben Sie Ihre Übersetzernummer ein (TP/1234/00)",
        regulations_acceptance_start: "Ich stimme zu",
        regulations_acceptance_link: "Serviceordnung",
        regulations_acceptance_end: "und die Verarbeitung meiner personenbezogenen Daten ...",
        signUpButton: "Anmelden",
        next: "Weiter",
        confirmPhone: "Bestätigen Sie Ihre Telefonnummer",
        confirmationByLetter: "Danke! Bitte warten Sie auf einen Brief von uns.",
        confirmEmail: "Bestätigen Sie Ihre E-Mail Adresse",
        confirmEmailInfo: "Wir haben Ihnen eine E-Mail geschickt. Klicken Sie auf den Link in der E-Mail, um Ihre Registrierung abzuschließen",
        confirmTranslatorEmailInfo: "'Wir haben Ihnen eine E-Mail an Ihre E-Mail-Adresse aus der Datenbank des Justizministeriums für staatlich geprüfte Übersetzer und Dolmetscher geschickt:",
        clickLinkToRegister: "Klicken Sie auf den Link in der E-Mail, um die Registrierung abzuschließen.",
        confirmTranslatorPhoneInfo: "'Wir haben Ihnen eine SMS mit dem Verifizierungscode an die Telefonnummer aus der Datenbank des Justizministeriums für staatlich geprüfte Übersetzer und Dolmetscher geschickt:",
        confirmTranslatorLetterInfo: "Wir werden innerhalb weniger Tage einen Einschreibebrief mit einem Verifizierungscode aus der Datenbank des Justizministeriums für für staatlich geprüfte Übersetzer und Dolmetscher an Ihre Postanschrift senden.",
        linkSentIconAlt: "Symbol für das Senden von Nachrichten",
        accountActivated: "Das Konto wurde aktiviert",
        goToYourAccount: "Gehen Sie zu Ihrem Konto",
        verificationError: "Fehler bei der Überprüfung",
        sendNewLink: "Neuen Link senden",
        sending: "Senden...",
        backToHomePage: "Zurück zur Startseite",
        goFurther: "Weitergehen",
        enterEmail: "Geben Sie Ihre E-Mail ein",
        translatorVerificationButton: "Haben Sie einen Bestätigungscode?",
        translatorVerification: {
            verifyAndFillData: "Daten überprüfen und vervollständigen",
            enterTpNumber: "Übersetzer-Code eingeben",
            enterVerificationCode: "Verifizierungscode eingeben",
            verificationCode: "Verifizierungscode",
            enterEmail: "Geben Sie Ihre E-Mail ein",
        },
    },
    forgotPassword: {
        title: "E-Mail eingeben, um Passwort zu ändern",
        email: "E-Mail",
        enterEmail: "Geben Sie Ihre E-Mail ein",
        backToLogin: "Zurück zur Anmeldung",
        next: "Weiter",
        linkSentTitle: "E-Mail prüfen, um Passwort zu ändern",
        linkSentIconAlt: "Symbol für das Senden von Nachrichten",
        linkSentInfo: "Wir haben Ihnen eine Nachricht geschickt. Klicken Sie auf den Link in der E-Mail, um Ihr Passwort zu ändern.",
    },
    changePassword: {
        title: "Neues Passwort eingeben",
        email: "E-Mail",
        enterEmail: "Geben Sie Ihre E-Mail ein",
        password: "Passwort",
        changePasswordButton: "Passwort ändern",
        passwordChanged: "Ihr Passwort wurde geändert"
    },
    translatorProfile: {
        absence: {
            absenceAtWork: "Arbeitspause",
            setAbsenceInWork: 'Arbeitspause festlegen',
            dateFrom: "Vom",
            dateTo: "Bis",
            indefinitely: "unbefristet",
            messageForClients: "Nachricht an Kunden",
            save: "Speichern",
            successDisableAbsence: "Die Pause wurde erfolgreich deaktiviert",
            successSetAbsence: "Die Pause wurde erfolgreich gesetzt",
            valid: {
                dateGreaterThan: "Das Datum darf nicht früher sein als das Datum des Urlaubs von"
            }
        }

    },
    tabs: {
        hello: "Hallo",
        requests: "Anfragen",
        orders: "Aufträge",
        invoice: "Rechnungen",
        repertory: "Urkundenrolle",
        profile: "Profil",
        settings: "Einstellungen"
    },
    validation: {
        email: "Die eingegebene E-Mail ist falsch",
        required: "Das Feld ist erforderlich",
        password: "Passwort soll min. 8 Zeichen, min. 1 Großbuchstabe, mindestens. 1 Kleinbuchstabe, min.1 Sonderzeichen enthalten",
        maxFileSize: "Die Datei mit dem Namen {fileName} ist zu groß",
        maximumFiles: "Die maximale Anzahl von Dateien ist: {maxFiles}",
        maximumSize: "Die maximale Gesamtgröße der Dateien beträgt: {maximumSize}",
        passwordConfirmation: "Die Passwörter müssen gleich sein",
        max_nip: "UstIdNr. muss aus 10 Ziffern bestehen",
    },
    errorCodes: {
        2: "Ungültiges Token",
        3: "Ungültiges Token",
        4: "Ungültiges Token",
        5: "Das Konto ist gesperrt",
        6: "Ungültiger Benutzername oder Passwort",
        7: "E-Mail nicht bestätigt",
        8: "Binärcode gesendet",
        9: "'Benutzer existiert bereits",
        10: "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
        14: "Die E-Mail wurde bereits bestätigt",
        15: "Der Benutzer wurde gelöscht",
        default: "Bei der Bearbeitung der Anfrage ist ein Fehler aufgetreten."
    },
    languageSettings: {
        interpreting: "Dolmetschen",
        deadline: "Frist",
        choosePreferredServices: "Wählen Sie bevorzugte Dienste",
        dateFrom: "von"
    },
    profileSettings: {
        aboutMe: "Über mich",
        save: "Speichern",
        saving: "Speichern ...",
        edit: "Bearbeiten",
        cancel: "Abbrechen",
        change: "Ändern",
        delete: "Löschen",
        deleting: "Löschen...",
        add: "Hinzufügen",
        yourPhoto: "Ihr Foto",
        profilePage: "Profilfoto",
        addFilePlaceholder: "Datei auswählen",
        choseFile: "Ausgewählt",
        experience: {
            title: "Erfahrung",
            companyName: "Firma",
            location: "Standort",
            position: "Position",
            workStart: "Beginn der Arbeit",
            workEnd: "Beendigung der Arbeit",
            workingHereCurrently: "Ich arbeite derzeit hier",
            currently: "derzeit"
        },
        education: {
            title: "Ausbildung",
            school: "Schule",
            specialization: "Fachrichtung",
            academicDegree: "Diplom",
            workStart: "Beginn",
            workEnd: "Abschluss (oder geplanter Abschluss)",
        },
    },
    settings: {
        save: "Speichern",
        saving: "Speichern ...",
        edit: "Bearbeiten",
        cancel: "Abbrechen",
        change: "Ändern",
        delete: "Löschen",
        deleting: "Löschen...",
        add: "Hinzufügen",
        arrowIconAlt: "Pfeil-Symbol",
        addresses: {
            title: "Adressen",
            lockedAddress: "Adresse gesperrt",
            lockIconAlt: "Schloss-Symbol",
            defaultAddress: "Standardadresse",
            setAsDefaultAddress: "Als Standard eingestellt",
            settingAsDefaultAddress: "Einstellung als Standard...",
            street: "Straße",
            buildingNumber: "Nummer",
            flatNumber: "Raum",
            city: "Ort",
            postcode: "Code",
        },
        emails: {
            title: "E-Mail-Adressen",
            lockedEmail: "E-Mail gesperrt",
            lockIconAlt: "Schloss-Symbol",
            defaultEmail: "Standard-E-Mail",
            setAsDefaultEmail: "Als Standard eingestellt",
            settingAsDefaultEmail: "Einstellung als Standard...",
            email: "E-Mail",
        },
        clientEmail: {
            title: "E-Mail-Adresse",
            email: "E-Mail",
        },
        phones: {
            title: "Telefonnummer",
            lockedPhone: "Telefon gesperrt",
            lockIconAlt: "Schloss-Symbol",
            defaultPhone: "Standardtelefon",
            setAsDefaultPhone: "Als Standard eingestellt",
            settingAsDefaultPhone: "Einstellung als Standard...",
            phone: "Telefon",
            phonePrefix: "Vorwahl",
            isLandline: "Festnetz",
        },
        invoicesData: {
            title: "Ihre Rechnungsdaten",
            street: "Straße",
            buildingNumber: "Nummer",
            flatNumber: "Raum",
            city: "Ort",
            postcode: "PLZ",
            name: "Name",
            nip: "UStIdNr.",
            postOffice: "Post",
            iAmVatPayer: "Ich bin ein Mehrwertsteuerpflichtige",
            basicNumeration: "Grundlegende Nummerierung",
            courtsProsecutorsPoliceNumeration: "Nummerierung von Gerichten, Staatsanwälten und Polizei",
            prefix: "Präfix",
            numerationType: "Nummerierungsart",
            format: "Format",
            startFrom: "Starten mit",
            registeredInBdo: "In BDO registriertes Unternehmen",
            bdoNumber: "BDO-Nummer",
            bankAccountsNumbers: "Bankkontonummern",
            currency: "Währung",
            country: "Land",
            bankAccountNumber: "Nummer",
            kindNumberingTypes: {
                1: "Jährlich",
                2: "Monatlich",
            },
            defaultInvoiceData: "Standarddaten",
            setAsDefaultInvoiceData: "Als Standard einstellen",
            settingAsDefaultInvoiceData: "Als Standard einstellen...",
        },
        removeAccount: {
            title: "Kontolöschung",
            confirmation: "Ich bestätige, dass ich mein Konto löschen möchte",
            removed: "Konto wurde gelöscht",
            cancelButton: "Abbrechen",
            confirmButton: "Bestätigen",
            confirmModalTitle: "Bestätigung der Kontolöschung'",
            confirmModalDescription: "Ein gelöschtes Konto kann nicht wieder aktiviert werden.<br/> Nach der Bestätigung werden Sie automatisch von der Anwendung abgemeldet.",
        },
        subscription: {
            title: "Ihr Abonnement",
            showYourSubscription: "Details zum Abonnement anzeigen",
            to: "Bis",
            validFrom: "Gültig ab",
            validTo: "Gültig bis",
            subscriptionId: "Abonnement-ID",
        },
        changePassword: {
            title: "Passwort ändern",
            currentPassword: "Aktuelles Passwort",
            newPassword: "Neues Passwort",
            repeatPassword: "Passwort wiederholen",
            passwordChanged: "Das Passwort wurde geändert",
        },
    },
    months: {
        1: "Januar",
        2: "Februar",
        3: "März",
        4: "April",
        5: "Mai",
        6: "Juni",
        7: "Juli",
        8: "August",
        9: "September",
        10: "Oktober",
        11: "November",
        12: "Dezember",
    },
    repertory: {
        itemsNeedCompleted: "Posten zu ergänzen",
        completedItems: "Ergänzt",
        adoptionDate: "Annahmedatum",
        refusalDate: "Ablehungsdatum",
        ordinalNumber: "OZ(1)",
        orderingDesignation: "Auftraggeberbezeichung",
        adoptionOrderDate: "Datum der Auftragaufnahme (2)",
        orderingDesignationOrPurchaser: "Bezeichnung des Auftraggebers oder des Bestellers der benannten Übersetzung (3)",
        translationForm: "Übersetzungsform",
        descriptionTranslatedDocument: "Beschreibung des übersetzten Dokuments",
        nameDateAndDesignation: "Name, Datum und Bezeichnung des Dokuments (4)",
        languageDocumentDrawek: "Sprache, in der das Dokument verfasst wird (5)",
        commentsOnType: "Anmerkungen zu Art, Form und Status des Dokuments (7)",
        activityDesignation: "Bezeichnung der Tätigkeit",
        typeOfActivityPerformed: "Art der ausgeübten Tätigkeit und Sprache der Übersetzung (8)",
        numberOfPagesOfTranslation: "Anzahl der Seiten der Übersetzung (9)",
        numberOfCopiesMade: "Anzahl der erstellten Kopien (10)",
        refusalToProvideTranslation: "Verweigerung der Bereitstellung von Übersetzungen für die in Artikel 15 des Gesetzes über den Beruf des vereidigten Übersetzers genannten Stellen",
        authorityRequestingTranslation: "Behörde, die eine Übersetzung fordert ( (17)",
        reasonForRefusalTranslation: "Grund für die Ablehnung der Übersetzung (18)",
        remarks: "Anmerkungen",
        interpretationDescription: "Beschreibung des Dolmetschens",
        date: "Datum",
        place: "Ort  (11)",
        duration: "Dauer",
        amountCollectedRemuneration: "Höhe der eingezogenen Vergütung (14)",
        dateOfReturnDocument: "Datum der Rücksendung des Dokuments mit Übersetzung ( 15)",
        range: "Bereich",
        edit: "Bearbeiten",
        export: "Exportieren",
        search: "Suchen",
    },
    metadata: {
        searchTranslator: {
            title: "Tłumacze przysięgli",
            description: "Tłumacze przysięgli",
        },
        home: {
            title: 'DE Znajdź tłumacza',
            description: 'Znajdź mi tłumacza przysięgłego',
        },
        findTranslator: {
            title: 'Znajdź tłumacza',
            description: 'Znajdź mi tłumacza przysięgłego',
        },
        forTranslators: {
            title: 'Dla tłumaczy przysięgłych',
            description: 'Jesteś tłumaczem? Zarejestruj się i aktywuj konto VIP, aby korzystać z wielu przydatnych funkcji',
        },
        // translatorPage: {
        //     title: 'Dla tłumaczy przysięgłych',
        //     description: 'Jesteś tłumaczem? Zarejestruj się i aktywuj konto VIP, aby korzystać z wielu przydatnych funkcji',
        // }
        login: {
            title: 'Logowanie',
            description: 'SwornApp - logowanie'
        },
        registration: {
            title: 'Rejestracja',
            description: 'SwornApp - rejestracja'
        },
        registrationEmailVerification: {
            title: 'Weryfikacja email',
            description: ''
        },
        registrationTranslatorVerification: {
            title: 'Weryfikacja tłumacza',
            description: ''
        },
        forgotPassword: {
            title: 'Zapomniałeś hasła?',
            description: 'Zapomniałeś hasła?'
        },
        changePassword: {
            title: 'Zmiana hasła',
            description: 'Zmień swoje hasło'
        },
        inquiries: {
            title: 'Zapytania',
            description: ''
        },
        orders: {
            title: 'Zamówienia',
            description: ''
        },
        invoices: {
            title: 'Faktury',
            description: ''
        },
        profileSettings: {
            title: 'Ustawienia profilu',
            description: ''
        },
        accountSettings: {
            title: 'Ustawienia konta',
            description: ''
        },
    }
}