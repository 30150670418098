import {RouteRecordRaw} from "vue-router";
import HomeView from "@/views/HomeView.vue";
import UserService from "@/services/userService";
import i18n from "@/i18n";

const routeTranslations = {
    translators: 'tlumacze',
    forTranslators: 'dla-tlumaczy',
    translator: 'tlumacz',
    login: 'logowanie',
    registration: 'rejestracja',
    verify: 'weryfikuj',
    forgotPassword: 'resetowanie-hasla',
    changePassword: 'zmiana-hasla',
    logout: 'wyloguj',
    inquiries: 'zapytania',
    orders: 'zlecenia',
    invoices: 'faktury',
    profileSettings: 'profil',
    accountSettings: 'konto',
    chat: 'czat',
    repertory: 'repertorium',
}

const routePaths = {
    home: '',
    findTranslator: routeTranslations.translators + '/:language/:city?/:types?',
    forTranslators: routeTranslations.forTranslators,
    translatorPage: routeTranslations.translator + '/:nameSlug/:tpNumberSlug',
    login: routeTranslations.login,
    registration: routeTranslations.registration,
    registrationEmailVerification: routeTranslations.registration + '/' + routeTranslations.verify + '/:userEmail',
    registrationTranslatorVerification: routeTranslations.registration + '/' + routeTranslations.translator + '/' + routeTranslations.verify + '/:tpNumber?',
    forgotPassword: routeTranslations.forgotPassword,
    changePassword: routeTranslations.changePassword + '/:userEmail',
    logout: routeTranslations.logout,
    inquiries: routeTranslations.inquiries,
    orders: routeTranslations.orders,
    invoices: routeTranslations.invoices,
    profileSettings: routeTranslations.profileSettings,
    accountSettings: routeTranslations.accountSettings,
    chat: routeTranslations.chat,
    repertories: routeTranslations.repertory,
};
i18n.global.locale = 'pl';
const routes: Array<RouteRecordRaw> = [
    {
        path: '/' + routePaths.home,
        name: 'home',
        component: HomeView,
        meta: {
            defaultLang: true,
            title: i18n.global.t('metadata.home.title'),
            description: i18n.global.t('metadata.home.description'),
        },
    },
    {
        path: '/' + routePaths.findTranslator,
        name: 'findTranslator',
        meta: {
            defaultLang: true,
            title: i18n.global.t('metadata.findTranslator.title'),
            description: i18n.global.t('metadata.findTranslator.description'),
        },
        component: () => import(/* webpackChunkName: "findTranslator" */ '../views/FindTranslator.vue'),
    },
    {
        path: '/' + routePaths.forTranslators,
        name: 'forTranslators',
        meta: {
            defaultLang: true,
            title: i18n.global.t('metadata.forTranslators.title'),
            description: i18n.global.t('metadata.forTranslators.description'),
        },
        component: () => import(/* webpackChunkName: "forTranslators" */ '../views/ForTranslators.vue')
    },
    {
        path: '/' + routePaths.translatorPage,
        name: 'translatorPage',
        meta: {
            defaultLang: true,
            title: i18n.global.t('metadata.translatorPage.title'),
            description: i18n.global.t('metadata.translatorPage.description'),
        },
        component: () => import(/* webpackChunkName: "translatorPage" */ '../views/TranslatorPage.vue')
    },

    //AUTH ROUTES//
    {
        path: '/' + routePaths.login,
        name: 'login',
        meta: {
            defaultLang: true,
            isRegistration: false,
            title: i18n.global.t('metadata.login.title'),
            description: i18n.global.t('metadata.login.description'),
        },
        component: () => import(/* webpackChunkName: "login" */ '../views/auth/LoginAndRegistrationView.vue')
    },
    {
        path: '/' + routePaths.registration,
        name: 'registration',
        meta: {
            defaultLang: true,
            isRegistration: true,
            title: i18n.global.t('metadata.registration.title'),
            description: i18n.global.t('metadata.registration.description'),
        },
        component: () => import(/* webpackChunkName: "registration" */ '../views/auth/LoginAndRegistrationView.vue')
    },
    {
        path: '/' + routePaths.registrationEmailVerification,
        name: 'registrationEmailVerification',
        meta: {
            defaultLang: true,
            isRegistration: true,
            title: i18n.global.t('metadata.registrationEmailVerification.title'),
            description: i18n.global.t('metadata.registrationEmailVerification.description'),
        },
        component: () => import(/* webpackChunkName: "registrationEmailVerification" */ '../views/auth/RegistrationEmailVerification.vue')
    },
    {
        path: '/' + routePaths.registrationTranslatorVerification,
        name: 'registrationTranslatorVerification',
        meta: {
            defaultLang: true,
            isRegistration: true,
            title: i18n.global.t('metadata.registrationTranslatorVerification.title'),
            description: i18n.global.t('metadata.registrationTranslatorVerification.description'),
        },
        component: () => import(/* webpackChunkName: "registrationTranslatorVerification" */ '../views/auth/RegistrationTranslatorVerification.vue')
    },
    {
        path: '/' + routePaths.forgotPassword,
        name: 'forgotPassword',
        meta: {
            defaultLang: true,
            title: i18n.global.t('metadata.forgotPassword.title'),
            description: i18n.global.t('metadata.forgotPassword.description'),
        },
        component: () => import(/* webpackChunkName: "forgotPassword" */ '../views/auth/ForgotPasswordView.vue')
    },
    {
        path: '/' + routePaths.changePassword,
        name: 'changePassword',
        meta: {
            defaultLang: true,
            title: i18n.global.t('metadata.changePassword.title'),
            description: i18n.global.t('metadata.changePassword.description'),
        },
        component: () => import(/* webpackChunkName: "changePassword" */ '../views/auth/ChangePasswordView.vue')
    },
    {
        path: '/' + routePaths.logout,
        name: 'logout',
        meta: {
            defaultLang: true,
            title: i18n.global.t('metadata.logout.title'),
            description: i18n.global.t('metadata.logout.description'),
        },
        component: {
            beforeRouteEnter(to, from, next) {
                const userService = new UserService();
                userService.logout();
            }
        }
    },

    //TRANSLATION PROFILE //
    {
        path: '/' + routePaths.inquiries,
        name: 'inquiries',
        meta: {
            defaultLang: true,
            dashboard: true,
            title: i18n.global.t('metadata.inquiries.title'),
            description: i18n.global.t('metadata.inquiries.description'),
        },
        component: () => import(/* webpackChunkName: "inquiries" */ '../views/InquiriesPage.vue')
    },
    {
        path: '/' + routePaths.orders,
        name: 'orders',
        meta: {
            defaultLang: true,
            dashboard: true,
            title: i18n.global.t('metadata.orders.title'),
            description: i18n.global.t('metadata.orders.description'),
        },
        component: () => import(/* webpackChunkName: "orders" */ '../views/OrdersPage.vue')
    },
    {
        path: '/' + routePaths.invoices,
        name: 'invoices',
        meta: {
            defaultLang: true,
            dashboard: true,
            title: i18n.global.t('metadata.invoices.title'),
            description: i18n.global.t('metadata.invoices.description'),
        },
        component: () => import(/* webpackChunkName: "invoices" */ '../views/InvoicesPage.vue')
    },
    {
        path: '/' + routePaths.repertories,
        name: 'repertories',
        meta: {
            defaultLang: true,
            dashboard: true,
            title: i18n.global.t('metadata.repertories.title'),
            description: i18n.global.t('metadata.repertories.description'),
        },
        component: () => import(/* webpackChunkName: "invoices" */ '../views/RepertoriesPage.vue')
    },
    {
        path: '/' + routePaths.profileSettings,
        name: 'profileSettings',
        meta: {
            defaultLang: true,
            dashboard: true,
            title: i18n.global.t('metadata.profileSettings.title'),
            description: i18n.global.t('metadata.profileSettings.description'),
        },
        component: () => import(/* webpackChunkName: "profileSettings" */ '../views/translator/TranslatorProfileSettings.vue')
    },
    {
        path: '/' + routePaths.accountSettings,
        name: 'accountSettings',
        meta: {
            defaultLang: true,
            dashboard: true,
            title: i18n.global.t('metadata.accountSettings.title'),
            description: i18n.global.t('metadata.accountSettings.description'),
        },
        component: () => import(/* webpackChunkName: "accountSettings" */ '../views/AccountSettings.vue')
    },
    {
        path: '/' + routePaths.chat,
        name: 'chat',
        meta: {
            defaultLang: true,
            dashboard: true,
        },
        component: () => import(/* webpackChunkName: "chat" */ '../views/translator/ChatPage.vue')
    },
];

export default routes;