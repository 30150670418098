export default {
    pages: {
        forTranslators: "Dla Tłumaczy",
        account: "Moje konto",
        contact: "Kontakt",
        regulations: "Regulamin",
        enquiries: "Zapytania",
        search: "Szukaj",
        privacyPolicy: "Polityka Prywatności",
        europeanFunds: 'Fundusze europejskie',
    },
    header: {
        closeAlt: "Zamknij",
        logout: "Wyloguj",
        accountIconAlt: "Ikona konta",
        searchIconAlt: "Ikona lupy",
        searchTranslator: "Szukaj tłumacza",
        selectPageLang: "Wybierz język strony",
    },
    footer: {
        accountIconAlt: "Ikona konta",
        searchIconAlt: "Ikona lupy",
        messageIconAlt: "Ikona wiadomości",
        enquiriesIconAlt: "Ikona tłumaczeń",
    },
    searchSection: {
        langLabel: "Język",
        translationTypeLabel: "Rodzaj tłumaczenia",
        locationLabel: "Miejscowość",
        defaultTranslationType: "Dowolne",
        search: "Wyszukaj",
        resultsCounter: "wyników",
        outOf: "z",
        list: "Lista",
        map: "Mapa",
        showFilters: "Pokaż filtry",
        hideFilters: "Ukryj filtry",
        interpreting: "Tylko tłumaczenia ustne",
    },
    wcag: {
        goToMain: "Przejdź do treści głównej",
        resizeFont: "Zmień wielkość tekstu. Obecna wielkość to {current} z {max}",
        googleMap: "Mapa przedstawiająca punkty na mapie, w których znajdują się tłumacze",
        interpreting: "Wybierz tylko tłumaczenia ustne"
    },
    homeView: {
        findSwornTranslator: "Znajdź tłumacza przysięgłego",
        imageOfSwornTranslatorAlt: "Zdjęcie tłumacza przysięgłego",
        howItWorks: "Jak to działa",
        itIsEasyText: "To proste niezależnie od tego, czy jesteś osobą prywatną, czy reprezentujesz firmę lub instytucję publiczną (urząd, sąd, policja). Znajdziesz u nas wszystkich tłumaczy przysięgłych z bazy Ministerstwa Sprawiedliwości.",
        arrowIconAlt: "Ikona strzałki",
        searchEngineIconAlt: "Ikona wyszukiwarki",
        searchEngineInfo: "Wyszukaj tłumacza oferującego interesującą Cię usługę",
        contactTheTranslatorIconAlt: "Ikona dzwonienia",
        contactTheTranslatorInfo: "Skontaktuj się z wybranym tłumaczem i uzgodnijcie warunki usługi",
        sendingDocumentsIconAlt: "Ikona dokumentów",
        sendingDocumentsInfo: "Tłumacz może poprosić o przesłanie dokumentów i przedstawi swoją ofertę",
        signedTranslationsIconAlt: "Ikona podpisanych dokumentów",
        signedTranslationsInfo: "Otrzymasz tłumaczenie poświadczone podpisem tłumacza",
        frequentlyChosenTranslators: "Najchętniej wybierani tłumacze",
        showMore: "Pokaż więcej",
        whoIsSwornTranslator: "Kim jest tłumacz przysięgły",
        personOfPublicTrust: "To osoba zaufania publicznego, która specjalizuje się w przekładzie m.in. dokumentów procesowych, urzędowych i uwierzytelnianiu obcojęzycznych odpisów takich dokumentów oraz może poświadczać tłumaczenia i odpisy wykonane przez inne osoby.",
        mustCertifyTheComplianceOfTheDocument: "Tłumacz przysięgły musi poświadczyć zgodność przełożonego dokumentu z oryginałem za pomocą pieczęci, która zawiera jego imię i nazwisko, język, w zakresie którego posiada uprawnienia, a także pozycję na liście tłumaczy przysięgłych.",
        certifiedTranslationsInclude: "Tłumaczenia uwierzytelnione obejmują najczęściej",
        proceduralAndOfficialDocuments: "dokumenty procesowe i urzędowe",
        workCertificates: "świadectwa pracy",
        schoolCertificates: "świadectwa szkolne",
        notarialDeeds: "akty notarialne",
        civilStatusRecords: "akty stanu cywilnego",
        lawsAndRegulations: "ustawy i rozporządzenia",
        certificates: "zaświadczenia i certyfikaty",
        imageOfMobileAppAlt: "Zdjęcie telefonu z aplikacją mobilną",
        downloadMobileApp: "Pobierz aplikację mobilną",
        mobileAppDescription: "Nascetur amet magna mattis aliquam ac sit. Sit lobortis nibh quam ultrices dictum commodo scelerisque cras. Ultricies tristique adipiscing sagittis est tincidunt pellentesque. Consectetur accumsan, lobortis ullamcorper ac condimentum sodales velit. Parturient at fermentum, orci quisque mauris scelerisque gravida nulla auctor.",
        appStoreAlt: "App store",
        googlePlayAlt: "Google play",
        logo_unia: "Logo przedstawiające flagę uni europejskiej",
        unia_section: "Logotyp uni europejskiej, polski oraz logotyp projektu",
    },
    forTranslators: {
        forSwornTranslators: "Dla tłumaczy przysięgłych",
        signUpIfYouAreTranslator: "Jesteś tłumaczem? Zarejestruj się i aktywuj konto VIP, aby korzystać z wielu przydatnych funkcji",
        swornAppIsDedicatedForTranslators: "SwornApp dedykowanych specjalnie dla tłumaczy przysięgłych!",
        benefits: "Korzyści",
        free: "Free",
        standard: "Standard",
        vip: "VIP",
        convenientCommunication: "Wygodna komunikacja i wymiana dokumentów z klientami",
        abilityToSpecifyPreferredServices: "Możliwość określenia preferowanych usług",
        editingBusinessCard: "Możliwość pełnej edycji swojej wizytówki",
        keepingOfTheRepertory: "Możliwość prowadzenia repertorium",
        convenientInvoicing: "Wygodne fakturowanie",
        onlinePaymentsForCustomers: "Płatności online dla klientów",
        checkMarkIconAlt: "Ikona zaznaczenia",
        notAvailableIconAlt: "Ikona niedostępności",
        month: "m-c",
        freeVipAccount: "Teraz konto VIP dla każdego za 0 zł przez pierwszy rok, jeżeli zainstalujesz aplikację mobilną SwornApp.",
        crateVipAccount: "Załóż konto VIP za 0 zł",
        imageOfSwornTranslatorAlt: "Zdjęcie tłumacza przysięgłego",
        freeAvailable: "dostępne z kontem free",
        freeNotAvailable: "niedostępne z kontem free",
        standardAvailable: "dostępne z kontem standard",
        standardNotAvailable: "niedostępne z kontem standard",
        vipAvailable: "dostępne z kontem vip",
        vipNotAvailable: "niedostępne z kontem vip",
        priceFree: "konto free za",
        priceStandard: "konto standard za",
        priceVip: "konto vip za",
        perMonth: "na miesiąc",
    },
    findTranslator: {
        starIconAlt: "Ikona gwiazdki",
        findTranslator: "Znajdź tłumacza przysięgłego",
        loading: "Ładowanie...",
        showMore: "Pokaż więcej",
    },
    starsCounter: {
        starIconAlt: "Ikona gwiazdki",
        rating: "Ocena",
        ratingOutOf: "na",
    },
    translatorPage: {
        pointerIconAlt: "Ikona znacznika",
        mailIconAlt: "Ikona koperty",
        phoneIconAlt: "Ikona telefonu",
        clockIconAlt: "Ikona zegara",
        translatorImageAlt: "Zdjęcie profilowe tłumacza",
        translationLanguages: "Języki tłumaczenia",
        askForOffer: "Zapytaj o ofertę",
        aboutMe: "O mnie",
        aboutTranslator: "O tłumaczu",
        aboutTranslatorContent: "Konto tłumacza pobrane z bazy tłumaczy przysięgłych Minsterstwa Sprawiedliwości.",
        signUpAsTranslatorInfo: "To Twoja wizytówka? Zarejestruj się i uzupełnij swoje dane!",
        signUpAsTranslatorButton: "Załóż konto jako tłumacz",
        experience: "Doświadczenie",
        currently: "obecnie",
        education: "Wykształcenie",
        deadline: "Termin",
        dateFrom: "od",
        writtenTranslations: "Tłumaczenia pisemne",
        interpreting: "Tłumaczenia ustne",
        yes: "Tak",
        no: "Nie",
        showMore: "Pokaż więcej",
        showLess: "Pokaż mniej",
        list: "Lista",
        map: "Mapa",
        sendRequest: "Wyślij zapytanie",
        sendRequestTo: "do",
        form: {
           language: "Języki",
            formOfTranslation: 'Forma',
            kindOf: "Rodzaj",
            deliveryMethod: "Sposób dostarczania oryginału",
            paymentMethod: "Sposób płatności",
            deadline: "Termin (do daty)",
            receivingMethod: "Sposób odbioru tłumaczenia",
            documentFile: "Dokument lub próbka",
            address: 'Adres',
            fromTime: 'Od godz.',
            toTime: 'Do godz.',
            date: 'Data',
            addFile: 'Dodaj plik',
            chooseFile: 'Wybrano:',
            cancel: "Anuluj",
            send: 'Wyślij',
            contentMessage: 'Treść aplikacji',
            addFilePlaceholder: 'Dodaj plik',
            enterMessagePlaceholder: "Wpisz treść",
            sendInquiryTo: "Wyślij zapytanie do:",
            yourMessage: "Twoja wiadomość",
            successfullySendInquiry: "Pomyślnie wysłano zapytanie",
            errorSendInquiry: "Błąd wysyłania zapytania",
            moreDates: "Więcej dat",
            removeDate: "usuń",
        },
    },
    translatorCard: {
        pointerIconAlt: "Ikona znacznika",
        address: "Adres",
        showOnMap: "Pokaż na mapie",
        seeProfile: "Zobacz profil",
        deadline: "Termin",
        clockIconAlt: "Ikona zegara",
        mailIconAlt: "Ikona koperty",
        mail: "Adres email",
        phone: "Telefon",
        phoneIconAlt: "Ikona telefonu",
        arrowRightIconAlt: "Ikona strzałki w prawo",
        votesNumber: "Liczba głosów",
        dateFrom: "od",
        deadlineAriaLabel: "Termin tłumaczenia około "
    },
    orders: {
        yes: "Tak",
        no: "Nie",
        search: "Szukaj",
        inProgress: "Zlecenia w trakcie",
        toBePaid: "Zlecenia do opłacenia",
        ended: "Zakończone zlecenia",
        principal: "Zleceniodawca",
        translator: "Tłumacz",
        admissionDate: "Data przyjęcia",
        completionDate: "Termin realizacji",
        translationForm: "Forma tłumaczenia",
        valuation: "Wycena",
        admitted: "Przyjęto",
        finish: "Zakończ",
        reject: "Odrzuć",
        editOrder: "Edytuj zlecenie",
        status: "Status",
        repertory: "Repertorium",
        markAsPaid: "Oznacz jako opłacone",
        payOnline: "Zapłać online",
        endOrder: "Zakończ zlecenie",
        downloadAnInvoice: "Pobierz fakturę",
        statuses: {
            None: "Brak",
            InProgress: "W trakcie",
            Rejected: "Odrzucone",
            ToBePaid: "Do opłacenia",
            Completed: "Zrealizowane"
        },
        orderFrom: "Zlecenie od",
        order: "Zlecenie",
        translationPrice: "Koszt tłumaczenia",
        additionalServices: "Usługi dodatkowe",
        gross: "brutto",
        net: "netto",
        pagesNumber: "Liczba stron",
        totalGrossAmount: "Całkowita kwota brutto",
        totalPriceToPay: "Razem do zapłaty",
        endTime: "Data zakończenia",
        paymentDeadline: "Data płatności",
        details: {
            language: "Język",
            formOfTranslation: "Forma",
            typeOfTranslation: "Rodzaj",
            deadline: "Termin do",
            originalDocumentDelivery: "Sposób dostarczenia oryginału",
            receiptOfTranslation: "Sposób odbioru tłumaczenia",
            paymentMethod: "Sposób płatności",
            methodOfCalculating: "Sposób wyliczenia kosztu usługi",
            currency: "Waluta",
        },
        markAsPaidModal: {
            title: "Potwierdzenie opłacenia zlecenia",
            description: "Czy na pewno chcesz potwierdzić opłacenie zlecenia?",
            markedAsPaid: "Zlecenie zostało oznaczone jako opłacone",
        },
        rejectOrderModal: {
            titleVIP: "Czy dodać odrzucone zlecenie do repertorium? ",
            titleBasic: "Potwierdzenie odrzucenia zlecenia",
            descriptionBasic: "Czy na pewno chcesz odrzucić zlecenie?",
            orderSuccessfullyRejected: "Zlecenie zostało odrzucone",
            cancelButton: "Anuluj",
            rejectButton: "Odrzuć zlecenie",
            createRepertory: "Tak",
            dontCreateRepertory: "Nie",
            createRepertoryAlt: "Dodaj do repertorium",
            dontCreateRepertoryAlt: "Nie dodawaj do repertorium",
            selectIconAlt: "Ikona wyboru",
        },
        endOrderModal: {
            saleDate: "Data sprzedaży",
            issueDate: "Data wystawienia",
            paymentDate: "Termin zapłaty",
            endOrderButton: "Zakończ zlecenie",
            endingOrderButton: "Zakończ zlecenie...",
            issueInvoice: "Wystaw fakturę",
            provideDataForInvoice: "Podaj dane zleceniodawcy do faktury",
            customerName: "Imię i nazwisko lub nazwa",
            nip: "NIP",
            street: "Ulica",
            buildingNumber: "Numer",
            flatNumber: "Lokal",
            city: "Miasto",
            postcode: "Kod",
            serviceName: "Nazwa usługi",
            pagesNumber: "Liczba stron",
            rate: "Stawka",
            gross: "brutto",
            net: "netto",
            totalAmount: "Całkowita kwota",
            amount: "Kwota",
            addAdditionalService: "Dodaj usługę dodatkową",
            customerAskedForAnInvoice: "Klient poprosił o fakturę",
            totalPriceToPay: "Razem do zapłaty",
            addToRepertory: "Dodaj do repertorium",
            priceWithVat: "Ceny z uwzględnieniem VAT",
            vatRate: "Stawka VAT",
            totalNet: "Razem (netto)",
            endedSuccessfully: "Pomyślnie zakończono zlecenie",
            setInvoiceDataInSettings: "Przejdź do ustawień i zdefiniuj dane do wystawiania faktur"
        }
    },
    invoices: {
        search: "Szukaj",
        issuedInvoices: "Wystawione faktury",
        customer: "Zleceniodawca",
        admissionDate: "Data przyjęcia",
        endDate: "Data zakończenia",
        translationForm: "Forma tłumaczenia",
        price: "Kwota",
        status: "Status",
        downloadAnInvoice: "Pobierz fakturę",
        admitted: "Przyjęto",
    },
    repertories: {
        title: {
            incomplete: "Pozycje wymagające uzupełnienia",
            completed: "Uzpełnione",
        },
        no: "Lp.",
        admissionDate: "Data przyjęcia",
        rejectionDate: "Data odmowy",
        customerDesignation: "Oznaczenie zleceniodawcy",
        language: "Język",
        translationForm: "Forma tłumaczenia",
        edit: "Edytuj",
        accepted: "Przyjeto",
        rejection: "Odmowa",
        editRepertory: {
            title: "Repertorium: pozycja nr",
            rejected: "odrzucone",
            orderAdmissionDate: "Data przyjęcia zlecenia",
            designation: "Oznaczenie zleceniodawcy albo zamawiającego wykonanie oznaczonego tłumaczenia",
            translatedDocumentDesc: "Opis tłumaczonego dokumentu",
            nameDateDocumentDesignation: "Nazwa, data i oznaczenie dokumentu",
            documentLang: "Język, w którym dokument został sporządzony",
            personThatPreparedDocument: "Osoba lub instytucja, która sporządziła dokument",
            comments: "Uwagi o rodzaju, formie i stanie dokumentu",
            activityDesignation: "Oznaczenie czynności",
            activityType: "Rodzaj wykonanej czynności i język tłumaczenia",
            amountCollected: "Wysokość pobranego wybagrodzenia",
            dateOfReturn: "Data zwrotu dokumentu wraz z tłumaczeniem",
            pagesNumber: "Liczba stron tłumaczenia",
            copiesNumber: "Liczba sporządzonych egzemplarzy",
            descriptionOfInterpreting: "Opis tłumaczenia ustnego",
            date: "Date",
            place: "Miejsce",
            duration: "Czas trwania",
            range: "Zakres",
            translationRejection: "Odmowa wykonania tłumaczenia na rzecz podmiotów, o których mowa w art. 15 ustawy o zawodzie tłumacza przysięgłego",
            rejectionDate: "Data odmowy",
            authorityRequestingTranslation: " Organ żądający tłumaczenia",
            rejectionReason: "Przyczyna odmowy tłumaczenia",
            rejectionComments: "Uwagi",
            close: "Zamknij",
            save: "Zapisz",
            saving: "Zapisywanie...",
            updatedSuccessfully: "Pomyślnie zaktualizowano dane",
        }
    },
    inquiries: {
        today: "Dzisiaj o ",
        to: "Do",
        search: "Szukaj",
        ordersFrom: "Zamówienie od",
        inquirieFrom: "Zapytanie od",
        messages: "Wiadomość",
        date: "Data",
        chat: {
            language: "Język",
            formOfTranslation: "Forma",
            typeOfTranslation: "Rodzaj",
            deadline: "Termin do",
            originalDocumentDelivery: "Sposób dostarczenia oryginału",
            receiptOfTranslation: "Sposób odbioru tłumaczenia",
            paymentMethod: "Sposób płatności",
            methodOfCalculatingId: "Sposób wyliczenia kosztu usługi",
            currency: "Waluta",
            typeOfRate: "Rodzaj stawki",
            grossRate: "Stawka",
            vatRate: "Stawka VAT",
            expiryDate: "Termin ważności oferty",
            inquireFrom: "Zapytanie od",
            methodOfCalculating: "Sposób wyliczenia kosztu usługi",
            priceNet: "Stawka",
            priceGross: "Stawka",
            net: 'Netto',
            gross: "Brutto",
            successMakeOffer: "Pomyslnie wysłano ofertę",
            failMakeOffer: "Wystąpił błąd podczas tworzenia oferty",
            successEditOffer: "Pomyślnie edytowano ofertę",
            failEditOffer: "Błąd podczas edycji oferty",
            successAcceptOffer: "Pomyślnie zaakceptowano ofertę",
            failAcceptOffer: "Błąd akceptacji oferty",
            address: "Adres",
            dates: 'Daty',
            date: 'Data',
            timeTo: 'Do godz.',
            timeFrom: 'Od godz.',
            successCloseInquiries: 'Pomyslnie zamknięto zapytanie',
            failCloseInquiries: 'Błąd podczas zamykania zapytanie',
            yes: 'Tak',
            no: 'Nie',
            successEditInquiries: "Pomyślnie edytowano zapytanie",
            failEditInquiries: "Błąd podczas edytowania zapytania",
            successRejectOfferAndCloseInquiries: "Pomyślnie odrzucono ofertę i zamknięto zapytanie",
            failRejectOfferAndCloseInquiries: "Błąd podczas odrzucania oferty.",
            cancel: "Anuluj",
            successRemoveFile: "Pomyślnie usunięto plik",
            failRemoveFile: "Błąd podczas usuwania pliku",
            translatorOffer: "Oferta tłumacza",

            invoiceModal: {
                doYouNeedInvoice: "Czy potrzebujesz faktury?",
                enterYouInvoiceDetails: "Podaj dane do faktury",
                fullName: "Imię i nazwisko lub nazwa",
                nip: "NIP",
                street: "Ulica",
                localNumber: "Numer",
                local: "Lokal",
                city: "Miejscowość",
                zipCode: "Kod",
                placeOrder: 'Złóż zamówienie',
                youOrderWasTransferred: "Twoje zlecenie zostało przekazane do tłumacza:",
                thanks: "Dziękujemy!",
                seeYoursOrders: "Zobacz twoje zlecenia",

                validation: {
                    tenNumberNip: 'Nip musi składać się z 10 cyfr'
                }
            },

            button: {
                cancel: 'Anuluj',
                makeOffer: "Przedstaw ofertę",
                sendOffer: "Wyślij ofertę",
                edit: "Edytuj",
                updates: "Aktualizuj",
                editOffer: "Edytuj",
                acceptOffer: "Zaakceptuj",
                rejectAndCloseOffer: "Odrzuć i zakończ",
                close: 'Zakończ',
                rejectInquirie: 'Odrzuć zapytanie',
                sendMessage: 'Napisz wiadomość',
                putMessage: "Napisz wiadomość",
            },

            confirm: {
                rejectTitle: 'Odrzuć i zakończ zapytanie',
                rejectDescription: 'Czy na pewno chcesz odrzucić zapytanie?',
                closeTitle: 'Zakończ zapytanie',
                closeDescription: 'Czy na pewno chcesz zakończyć zapytanie?',
                rejectAndAddToRepertory: 'Czy dodać odrzucone zlecenie do repertorium?',

            },

            validation: {
                language: 'Podany język nie istnieje',
                formOfTranslation: 'Podana forma nie istnieje',
                typeOfTranslation: 'Rodzaj tłumaczenia istnieje',
                failSendFile: 'Błąd przesyłania plików',
                checkExtensions: "Sprawdź rozszerzenia plików",
            }
        },

    },
    notFoundPage: {
      error404: "Błąd 404",
      pageNotExist: "Nie ma takiej strony",
      backToHomePage: "Wróć na stronę główną",
      pageNotFoundIconAlt: "Ikona strony 404",
    },
    modal: {
        close: "Zamknij"
    },
    autocomplete: {
        loading: "Ładowanie...",
        noResult: "Brak wyników"
    },
    loginAndRegistrationView: {
        title: "Zaloguj się lub załóż konto",
        login: "Logowanie",
        registration: "Rejestracja",
        eyeIconAlt: "Ikona oka",
        showPassword: "Pokaż hasło",
        hidePassword: "Ukryj hasło",
    },
    login: {
        email: "E-mail",
        password: "Hasło",
        loginButton: "Zaloguj się",
        forgotPassword: "Nie pamiętasz hasła"
    },
    registration: {
        accountActivatedAlt: "Ikona potwierdzenia",
        customerOrCompany: "Klient indywidualny lub firma",
        publicInstitution: "Instytucja publiczna",
        swornTranslator: "Tłumacz przysięgły",
        selectIconAlt: "Ikona wyboru",
        email: "E-mail",
        businessEmail: "E-mail służbowy",
        businessPhone: "Telefon służbowy",
        institutionName: "Instytucja (dokładna nazwa)",
        institutionWebsite: "Adres strony WWW instytucji",
        position: "Pozycja",
        name: "Imię",
        surname: "Nazwisko",
        password: "Hasło",
        translatorNumber: "Podaj Twój numer tłumacza (TP/1234/00)",
        regulations_acceptance_start: "Wyrażam zgodę na",
        regulations_acceptance_link: "regulaminu serwisu",
        regulations_acceptance_end: "i przetwarzanie moich danych osobowych...",
        signUpButton: "Zarejestruj się",
        next: "Dalej",
        confirmPhone: "Potwierdź swój numer telefonu",
        confirmationByLetter: "Dziękujemy! Czekaj na list od nas",
        confirmEmail: "Potwierdź swój adres e-mail",
        confirmEmailInfo: "Wysłaliśmy do Ciebie wiadomość. Kliknij w link z maila, aby zakończyć rejestrację.",
        confirmTranslatorEmailInfo: "Wysłaliśmy do Ciebie wiadomość na adres e-mail z bazy tłumaczy przysięgłych Ministerstwa Sprawiedliwości:",
        clickLinkToRegister: "Kliknij w link z maila, aby zakończyć rejestrację.",
        confirmTranslatorPhoneInfo: "Wysłaliśmy do Ciebie SMS z kodem weryfikacyjnym na numer telefonu z bazy tłumaczy przysięgłych Ministerstwa Sprawiedliwości:",
        confirmTranslatorLetterInfo: "W ciągu kilku dni wyślemy na Twój adres pocztowy z bazy tłumaczy przysięgłych Ministerstwa Sprawiedliwości list polecony z kodem werfikacyjnym.",
        linkSentIconAlt: "Ikona wysłania wiadomości",
        accountActivated: "Konto zostało aktywowane",
        goToYourAccount: "Przejdź do swojego konta",
        verificationError: "Błąd podczas weryfikacji",
        sendNewLink: "Wyślij nowy link",
        sending: "Wysyłanie...",
        backToHomePage: "Wróć na stronę główną",
        goFurther: "Przejdź dalej",
        enterEmail: "Wpisz swój e-mail",
        translatorVerificationButton: "Masz kod weryfikacyjny?",
        translatorVerification: {
            verifyAndFillData: "Zweryfikuj i uzupełnij dane",
            enterTpNumber: "Wpisz kod łumacza",
            enterVerificationCode: "Wpisz kod weryfikacyjny",
            verificationCode: "Kod weryfikacyjny",
            enterEmail: "Wpisz swój e-mail",
        },
    },
    forgotPassword: {
        title: "Podaj e-mail do zmiany hasła",
        email: "E-mail",
        enterEmail: "Wpisz swój e-mail",
        backToLogin: "Wróć do logowania",
        next: "Dalej",
        linkSentTitle: "Sprawdź pocztę, żeby zmienić hasło",
        linkSentIconAlt: "Ikona wysłania wiadomości",
        linkSentInfo: "Wysłaliśmy do Ciebie wiadomość. Kliknij w link z maila, aby zmienić hasło.",
    },
    changePassword: {
        title: "Podaj nowe hasło",
        email: "E-mail",
        enterEmail: "Wpisz swój e-mail",
        password: "Hasło",
        changePasswordButton: "Zmień hasło",
        passwordChanged: "Twoje hasło zostało zmienione"
    },
    translatorProfile: {
        absence: {
            absenceAtWork: "Przerwa w pracy",
            setAbsenceInWork: 'Ustaw przerwę w pracy',
            dateFrom: "Od daty",
            dateTo: "Do daty",
            indefinitely: "Bezterminowo",
            messageForClients: "Wiadomość dla klientów",
            save: "Zapisz",
            successDisableAbsence: "Pomyślnie wyłączono przerwę",
            successSetAbsence: "Pomyślnie ustawiono przerwę",
            valid: {
                dateGreaterThan: "Data nie może być mniejsza niż data urlop od"
            }
        }

    },
    tabs: {
        hello: "Witaj",
        requests: "Zapytania",
        orders: "Zlecenia",
        invoice: "Faktury",
        repertory: "Repertorium",
        profile: "Profil",
        settings: "Ustawienia"
    },
    validation: {
        email: "Podany email jest nieprawidłowy",
        required: "Pole jest wymagane",
        password: "Hasło powinno zawierać: min. 8 znaków, min. 1 duża litera, min. 1 mała litera, min.1 znak specjalny",
        maxFileSize: "Plik o nazwie {fileName} jest za duży",
        maximumFiles: "Maksymalna liczba plików to: {maxFiles}",
        maximumSize: "Łączna maksymalna wielkość plików to: {maximumSize}",
        passwordConfirmation: "Hasła muszą być takie same",
        max_nip: "Nip musi składać się z 10 cyfr",
        invalidFormat: 'Niepoprawny format',
        lettersOnly: 'Pole może zawierać tylko litery',
        numbersOnly: 'Pole może zawierać tylko cyfry',
        banksAccountRequired: 'Wymagany jest przynajmniej jeden numer konta',
    },
    errorCodes: {
        2: "Nieprawidłowy token",
        3: "Nieprawidłowy token",
        4: "Nieprawidłowy token",
        5: "Konto jest zablokowane",
        6: "Nieprawidłowa nazwa użytkownika lub hasło",
        7: "E-mail nie został potwierdzony",
        8: "Wysłano kod dwuskładnikowy",
        9: "Użytkownik już istnieje",
        10: "Wystąpił błąd. Spróbuj ponownie później.",
        14: "E-mail został juz potwierdzony",
        15: "Użytkownik został usunięty",
        16: "Podany kod weryfikacyjny wygasł",
        17: "Podany kod weryfikacyjny jest niepoprawny",
        18: "Kod weryfikacyjny został już wysłany",
        19: "Tłumacz nie istnieje",
        20: "Tłumacz ma już konto",
        default: "Podczas przetwarzania żądania wystąpił błąd.",
        invoice_data_not_found_request: "Brak danych do faktury. Uzupełnij je w swoim profilu."
    },
    languageSettings: {
        interpreting: "tłumaczenia ustne",
        deadline: "Termin",
        choosePreferredServices: "Wybierz preferowane usługi",
        dateFrom: "od"
    },
    profileSettings: {
        aboutMe: "O mnie",
        save: "Zapisz",
        saving: "Zapisywanie...",
        edit: "Edytuj",
        cancel: "Anuluj",
        change: "Zmień",
        delete: "Usuń",
        deleting: "Usuwanie...",
        add: "Dodaj",
        yourPhoto: "Twoje zdjęcie",
        profilePage: "Zdjęcie profilowe",
        addFilePlaceholder: "Wybierz plik",
        choseFile: "Wybrano",
        profilePhoto: {
            confirmDeletingModalTitle: "Usuwanie zdjęcia profilowego",
            confirmDeletingModalDescription: "Czy na pewno chcesz usunąć zdjęcie?",
        },
        experience: {
            title: "Doświadczenie",
            companyName: "Firma",
            location: "Lokalizacja",
            position: "Stanowisko",
            workStart: "Rozpoczęcie pracy",
            workEnd: "Zakończenie pracy",
            workingHereCurrently: "Pracuję tu obecnie",
            currently: "obecnie",
            confirmDeletingModalTitle: "Usuwanie danych o doświadczeniu",
            confirmDeletingModalDescription: "Czy na pewno chcesz usunąć dane?",
        },
        education: {
            title: "Wykształcenie",
            school: "Szkoła",
            specialization: "Specjalizacja",
            academicDegree: "Stopień naukowy",
            workStart: "Rozpoczęcie",
            workEnd: "Zakończenie (lub planowane zakończenie)",
            confirmDeletingModalTitle: "Usuwanie danych o edukacji",
            confirmDeletingModalDescription: "Czy na pewno chcesz usunąć dane?",
        },
    },
    settings: {
        save: "Zapisz",
        saving: "Zapisywanie...",
        edit: "Edytuj",
        cancel: "Anuluj",
        change: "Zmień",
        delete: "Usuń",
        deleting: "Usuwanie...",
        add: "Dodaj",
        arrowIconAlt: "Ikona strzałki",
        addresses: {
            title: "Adresy",
            lockedAddress: "Adres zablokowany",
            lockIconAlt: "Ikona kłódki",
            defaultAddress: "Adres domyślny",
            setAsDefaultAddress: "Ustaw jako domyślny",
            settingAsDefaultAddress: "Ustawianie jako domyślny...",
            street: "Ulica",
            buildingNumber: "Numer",
            flatNumber: "Lokal",
            city: "Miasto",
            postcode: "Kod",
            confirmDeletingModalTitle: "Usuwanie adresu",
            confirmDeletingModalDescription: "Czy na pewno chcesz usunąć adres?",
        },
        emails: {
            title: "Adresy e-mail",
            lockedEmail: "E-mail zablokowany",
            lockIconAlt: "Ikona kłódki",
            defaultEmail: "E-mail domyślny",
            setAsDefaultEmail: "Ustaw jako domyślny",
            settingAsDefaultEmail: "Ustawianie jako domyślny...",
            email: "Email",
            confirmDeletingModalTitle: "Usuwanie adresu email",
            confirmDeletingModalDescription: "Czy na pewno chcesz usunąć adres email?",
        },
        repertories: {
            title: "Numeracja w repertorium",
            startFrom: "Zacznij od Lp.",
        },
        clientEmail: {
            title: "Adres e-mail",
            email: "Email",
        },
        phones: {
            title: "Telefony",
            lockedPhone: "Telefon zablokowany",
            lockIconAlt: "Ikona kłódki",
            defaultPhone: "Telefon domyślny",
            setAsDefaultPhone: "Ustaw jako domyślny",
            settingAsDefaultPhone: "Ustawianie jako domyślny...",
            phone: "Telefon",
            phonePrefix: "Numer kierunkowy",
            isLandline: "Telefon stacjonarny",
            confirmDeletingModalTitle: "Usuwanie numeru telefonu",
            confirmDeletingModalDescription: "Czy na pewno chcesz usunąć numer telefonu?",
        },
        invoicesData: {
            title: "Twoje dane do faktury",
            street: "Ulica",
            buildingNumber: "Numer",
            flatNumber: "Lokal",
            city: "Miejscowość",
            postcode: "Kod",
            name: "Nazwa",
            nip: "NIP",
            postOffice: "Poczta",
            iAmVatPayer: "Jestem płatnikiem VAT",
            basicNumeration: "Podstawowa numeracja",
            courtsProsecutorsPoliceNumeration: "Numeracja Sądy, prokuratura i policja",
            prefix: "Prefix",
            numerationType: "Rodzaj numeracji",
            format: "Format",
            startFrom: "Zacznij od",
            registeredInBdo: "Firma zarejestrowana in BDO",
            bdoNumber: "Numer BDO",
            bankAccountsNumbers: "Numery kont bankowych",
            currency: "Waluta",
            country: "Kraj",
            bankAccountNumber: "Numer",
            kindNumberingTypes: {
                1: "Roczna",
                2: "Miesięczna",
            },
            defaultInvoiceData: "Dane domyślne",
            setAsDefaultInvoiceData: "Ustaw jako domyślne",
            settingAsDefaultInvoiceData: "Ustawianie jako domyślne...",
            confirmDeletingModalTitle: "Usuwanie danych do faktury",
            confirmDeletingModalDescription: "Czy na pewno chcesz usunąć dane?",
        },
        removeAccount: {
            title: "Usuwanie konta",
            confirmation: "Potwierdzam chęć usunięcia konta",
            removed: "Konto zostało usunięte",
            cancelButton: "Anuluj",
            confirmButton: "Potwierdź",
            confirmModalTitle: "Potwierdź usunięcie konta",
            confirmModalDescription: "Usuniętego konta nie będzie można aktywować ponownie.<br/> Po zatwierdzeniu zostaniesz automatycznie wylogowany z aplikacji.",
        },
        subscription: {
            title: "Twój abonament",
            showYourSubscription: "Pokaż szczegóły abonamentu",
            to: "do",
            validFrom: "Ważny od",
            validTo: "Ważny do",
            subscriptionId: "ID abonamentu",
        },
        changePassword: {
            title: "Zmień hasło",
            currentPassword: "Aktualne hasło",
            newPassword: "Nowe hasło",
            repeatPassword: "Powtórz hasło",
            passwordChanged: "Hasło zostało zmienione",
        },
    },
    months: {
        1: "Styczeń",
        2: "Luty",
        3: "Marzec",
        4: "Kwiecień",
        5: "Maj",
        6: "Czerwiec",
        7: "Lipiec",
        8: "Sierpień",
        9: "Wrzesień",
        10: "Październik",
        11: "Listopad",
        12: "Grudzień",
    },
    repertory: {
        itemsNeedCompleted: "Pozycje wymagające uzupełnienia",
        completedItems: "Uzupełnione",
        adoptionDate: "Data przyjęcia",
        refusalDate: "Data odmowy",
        ordinalNumber: "Lp.(1)",
        orderingDesignation: "Oznaczenie zleceniodawcy",
        adoptionOrderDate: "Data przyjęcia zlecenia (2)",
        orderingDesignationOrPurchaser: "Oznaczenie zleceniodawcy albo zamawiającego wykonanie oznaczonego tłumaczenia (3)",
        translationForm: "Forma tłumaczenia",
        descriptionTranslatedDocument: "Opis tłumaczonego dokumentu",
        nameDateAndDesignation: "Nazwa, data i oznaczenie dokumentu (4)",
        languageDocumentDrawek: "Język, w którym dokument zostanie sporządzony (5)",
        commentsOnType: "Uwagi o rodzaju, formie i stanie dokumentu (7)",
        activityDesignation: "Oznaczenie czynności",
        typeOfActivityPerformed: "Rodzaj wykonanej czynności i język tłumaczenia (8)",
        numberOfPagesOfTranslation: "Liczba stron tłumaczenia (9)",
        numberOfCopiesMade: "Liczba sporządzonych egzemplarzy (10)",
        refusalToProvideTranslation: "Odmowa wykonania tłumaczenia na rzecz podmiotów, o których mowa w art. 15 ustawy o zawodzie tłumacza przysięgłego",
        authorityRequestingTranslation: "Organ żądający tłumaczenia (17)",
        reasonForRefusalTranslation: "Przyczyna odmowy tłumaczenia (18)",
        remarks: "Uwagi",
        interpretationDescription: "Opis tłumaczenia ustnego",
        date: "Data",
        place: "Miejsce (11)",
        duration: "Czas trwania",
        amountCollectedRemuneration: "Wysokość pobranego wybagrodzenia (14)",
        dateOfReturnDocument: "Data zwrotu dokumentu wraz z tłumaczeniem ( 15)",
        range: "Zakres",
        edit: "Edytuj",
        export: "Eksportuj",
        search: "Szukaj",
    },
    metadata: {
        searchTranslator: {
            title: "Tłumacze przysięgli",
            description: "Tłumacze przysięgli",
        },
        home: {
            title: 'Znajdź tłumacza',
            description: 'Znajdź mi tłumacza przysięgłego',
        },
        findTranslator: {
            title: 'Znajdź tłumacza',
            description: 'Znajdź mi tłumacza przysięgłego',
        },
        forTranslators: {
            title: 'Dla tłumaczy przysięgłych',
            description: 'Jesteś tłumaczem? Zarejestruj się i aktywuj konto VIP, aby korzystać z wielu przydatnych funkcji',
        },
        // translatorPage: {
        //     title: 'Dla tłumaczy przysięgłych',
        //     description: 'Jesteś tłumaczem? Zarejestruj się i aktywuj konto VIP, aby korzystać z wielu przydatnych funkcji',
        // }
        login: {
            title: 'Logowanie',
            description: 'SwornApp - logowanie'
        },
        registration: {
            title: 'Rejestracja',
            description: 'SwornApp - rejestracja'
        },
        registrationEmailVerification: {
            title: 'Weryfikacja email',
            description: ''
        },
        registrationTranslatorVerification: {
            title: 'Weryfikacja tłumacza',
            description: ''
        },
        forgotPassword: {
            title: 'Zapomniałeś hasła?',
            description: 'Zapomniałeś hasła?'
        },
        changePassword: {
            title: 'Zmiana hasła',
            description: 'Zmień swoje hasło'
        },
        inquiries: {
            title: 'Zapytania',
            description: ''
        },
        orders: {
            title: 'Zamówienia',
            description: ''
        },
        invoices: {
            title: 'Faktury',
            description: ''
        },
        repertories: {
            title: 'Repertorium',
            description: ''
        },
        profileSettings: {
            title: 'Ustawienia profilu',
            description: ''
        },
        accountSettings: {
            title: 'Ustawienia konta',
            description: ''
        },
    }
}
